import React, { useState, useEffect } from 'react';
import { Button, Input, Form, Switch } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { removeVietnamese } from '@utils/Functions/RemoveVietnamese';
import TextArea from 'antd/es/input/TextArea';
import Ckeditor from '@components/Editor/CkeditorEnable';
import UploadMono from '@components/UploadImage/UploadV3';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';
import { v4 as uuidv4 } from 'uuid';
import { convertToSlug } from '@utils/Functions/RemoveVietnamese';

export const ServiceForm = ({
  initialValues,
  onFinish,
  onFinishFailed,
  content,
  setContent,
  listData,
  setListData,
}) => {
  const [form] = Form.useForm();
  const handleAdd = () => {
    const newlist = [...listData];
    newlist.push({ id: uuidv4(), image: '', title: '', text: '' });
    setListData(newlist);
  };
  const handleDelete = (idToDelete: string) => {
    const updatedList = listData.filter((item) => item.id !== idToDelete);
    setListData(updatedList);
  };

  const handleEdit = (id: string, newValue: string, fieldName: string) => {
    setListData((prevList) => {
      const updatedList = prevList.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            [fieldName]: newValue,
          };
        }
        return item;
      });
      return updatedList;
    });
  };
  const handleChangeName = (value) => {
    form.setFieldsValue({
      slug: convertToSlug(value),
    });
  };
  const forlderName = 'services';

  return (
    <Form
      form={form}
      key={JSON.stringify(initialValues)}
      initialValues={initialValues}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      className="mb-10 main-add-form"
    >
      <Form.Item
        label="Tên dịch vụ"
        name="name"
        rules={[
          {
            required: true,
            message: '',
          },
        ]}
        className="mb-0"
      >
        <div className="flex mg-bttom-0">
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tên dịch vụ',
              },
            ]}
            className="w-full mg-bttom-0"
          >
            <Input
              placeholder="Nhập tên dịch vụ"
              onChange={(e) => handleChangeName(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="code"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập mã dịch vụ',
              },
            ]}
            className="mg-bttom-0"
          >
            <Input
              placeholder="Nhập mã dịch vụ"
              className="w-[120px] ml-3"
              disabled
            />
          </Form.Item>
        </div>
      </Form.Item>
      <Form.Item
        label="Đường dẫn bài viết"
        name="slug"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập đường dẫn bài viết',
          },
        ]}
        className="mb-0"
      >
        <Input placeholder="Nhập đường dẫn bài viết" />
      </Form.Item>
      <Form.Item label="Thời gian phục vụ" name="time" className="mb-0">
        <Input placeholder="Thời gian phục vụ" disabled />
      </Form.Item>
      <Form.Item label="Giá" name="cost" className="mb-0">
        <Input placeholder="Giá dịch vụ" disabled />
      </Form.Item>
      <Form.Item
        label="Hiển thị trên booking"
        name="active_booking"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn hiển thị booking',
          },
        ]}
      >
        <Switch
          checkedChildren="Mở"
          unCheckedChildren="Đóng"
          defaultChecked
          disabled
        />
      </Form.Item>
      <Form.Item
        label="Trạng thái hoạt động"
        name="active"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn trạng thái hoạt động',
          },
        ]}
      >
        <Switch
          checkedChildren="Mở"
          unCheckedChildren="Đóng"
          defaultChecked
          disabled
        />
      </Form.Item>
      <div className="mb-5 ml-10 font-bold">Thông tin dịch vụ</div>
      <div className="mx-10 mb-5">
        <Ckeditor data={content} setData={setContent} folder={'service'} />
      </div>
      <div className="mb-5 ml-10 font-bold">
        Mô tả quy trình các bước dịch vụ
      </div>
      {listData &&
        listData.map((item, index) => (
          <div key={item.id} className="flex mx-10">
            <UploadMono
              setImageUrl={(value) => handleEdit(item.id, value, 'image')}
              urlImage={item.image}
              imageClass={ImageCalssEnum.SquarePhoto}
              forlderName={forlderName}
            ></UploadMono>
            <div className="w-full ml-10">
              <Input
                placeholder="Nhập tiêu đề (Bước 1,2,3..)"
                className="mb-3"
                value={item.title}
                onChange={(e) => handleEdit(item.id, e.target.value, 'title')}
              />
              <TextArea
                placeholder="Nhập mô tả"
                value={item.text}
                onChange={(e) => handleEdit(item.id, e.target.value, 'text')}
              />
            </div>
            <Button onClick={() => handleDelete(item.id)} className="ml-1">
              Xoá
            </Button>
          </div>
        ))}
      <div className="flex justify-end mx-10 mb-5">
        <Button onClick={handleAdd} type="primary">
          Thêm
        </Button>
      </div>
      <div className="ml-10 font-bold">Thông tin SEO</div>
      <Form.Item label="Title" name="titleSeo" className="mb-0">
        <Input placeholder="Nhập title SEO" />
      </Form.Item>
      <Form.Item label="Keywork" name="keywordSeo" className="mb-0">
        <Input placeholder="Nhập Keywork SEO" />
      </Form.Item>
      <Form.Item label="Description" name="descriptionSeo" className="mb-0">
        <TextArea placeholder="Nhập mô tả SEO" />
      </Form.Item>
      <div className="flex justify-end">
        <Button type="primary" className="button-submit" htmlType="submit">
          {!initialValues?.config ? 'Tạo mới ' : 'Cập nhật'}
        </Button>
      </div>
    </Form>
  );
};
