import React, { useState, useEffect, useRef } from 'react';
import { Spin } from 'antd';
import { handleUpload } from '@services';
import { useCookies } from 'react-cookie';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Cookies from 'js-cookie';

type Props = {
  data: string | undefined; // cho phép `data` có thể là `undefined`
  setData: React.Dispatch<React.SetStateAction<string | undefined>>; // cho phép giá trị mới có thể là `undefined`
  folder: string;
};

const Ckeditor = ({ data, setData, folder }: Props) => {
  const [cookies] = useCookies(['accessToken']);
  const token = cookies['accessToken'];
  const editorRef = useRef<{ CKEditor: any; ClassicEditor: any }>({
    CKEditor: null,
    ClassicEditor: null,
  });
  const [editorLoaded, setEditorLoaded] = useState(false);

  useEffect(() => {
    editorRef.current = {
      CKEditor: CKEditor,
      ClassicEditor: require('ckeditor5-custom-build/build/ckeditor'),
    };
    setEditorLoaded(true);
  }, []);

  const handleEditorChange = (event: any, editor: any) => {
    const newData = editor.getData();
    setData(newData);
  };
  function replaceSpacesWithDash(str) {
    return str.replace(/\s+/g, '-');
  }
  function convertValueWithDashes(value) {
    const strWithDashes = replaceSpacesWithDash(value);
    return strWithDashes;
  }
  const uploadAdapter = (loader: any) => {
    const accessToken = Cookies.get('accessToken');
    return {
      upload: () => {
        return new Promise((resolve: any, reject: any) => {
          loader.file.then((file: any) => {
            const formData = new FormData();

            // Tạo một file mới với tên mới
            const newFile = new File(
              [file],
              Date.now() + '-' + convertValueWithDashes(file.name),
              {
                type: file.type,
              },
            );

            // Thêm file mới vào formData
            formData.append('file', newFile);
            formData.append('folder', '/ckeditor');

            fetch(process.env.REACT_APP_UPLOAD_DOMAIN + `/images`, {
              method: 'post',
              body: formData,
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            })
              .then((res: any) => {
                console.log(res, 'res');
                return res.json();
              })
              .then((res: any) => {
                console.log(res, 'res');
                resolve({
                  default: res.data,
                });
              })
              .catch((err) => {
                console.log(err);
                reject(err);
              });
          });
        });
      },
    };
  };

  function uploadPlugin(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = (
      loader: any,
    ) => {
      console.log(loader, 'loader');
      return uploadAdapter(loader);
    };
  }
  return (
    <>
      {editorLoaded ? (
        <>
          <editorRef.current.CKEditor
            editor={editorRef.current.ClassicEditor}
            data={data}
            onChange={handleEditorChange}
            config={{
              extraPlugins: [uploadPlugin],
            }}
          />
        </>
      ) : (
        <Spin />
      )}
    </>
  );
};

export default Ckeditor;
