import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { notification } from 'antd';
import { handleService } from '@services';
import { useNavigate } from 'react-router-dom';
import { breadcrumAddGroupService as breadcrumbItems } from '@components/Breadcrumb';
import UploadCustomV2 from '@components/UploadImage/UploadMono';
import { GroupServicesForm } from '@components/Form/GroupServices';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';

export const AddGroupServices = () => {
  const [content, setContent] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const navigate = useNavigate();
  const initialValues = {
    showHome: false,
    activeBooking: true,
    config: false,
  };

  const [imageUrl, setImageUrl] = useState<string>('');

  const title = 'Tạo mới nhóm dịch vụ';
  const titleButton = '';
  const routerAdd = '';
  const forlderName = 'groupservices';

  const onFinish = (values: any) => {
    const serviceIdsData = dataSource.map((data) => {
      return data.id;
    });
    const body = {
      name: values.name,
      activeBooking: values.activeBooking,
      showHome: values.showHome,
      slug: values.slug,
      content: content,
      serviceIds: serviceIdsData,
      seo: {
        title: values.titleSeo,
        keyword: values.keywordSeo,
        description: values.descriptionSeo,
      },
    };
    console.log(body, 'body');
    handleService
      .CreateGroupService(body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Bạn đã tạo thành công nhóm dịch vụ mới',
          });
          navigate('/group-service/list?pageSize=10&pageIndex=1');
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Có lỗi xảy ra trong quá trình xử lý dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="main main-add">
        <div className="w-full pt-10 bg-white">
          <GroupServicesForm
            initialValues={initialValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            content={content}
            setContent={setContent}
            dataSource={dataSource}
            setDataSource={setDataSource}
          />
        </div>
      </div>
    </>
  );
};
