import React, { useState, useEffect } from 'react';
import { Button, Input, Form, Switch, Table, notification } from 'antd';
import {
  SearchOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import { CategoryType } from './type';
import { removeVietnamese } from '@utils/Functions/RemoveVietnamese';
import Ckeditor from '@components/Editor/CkeditorEnable';
import { convertToSlug } from '@utils/Functions/RemoveVietnamese';
import { handleService } from '@services';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import TextArea from 'antd/es/input/TextArea';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: 'none', cursor: 'move' }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

export const GroupServicesForm = ({
  initialValues,
  onFinish,
  onFinishFailed,
  content,
  setContent,
  dataSource,
  setDataSource,
}) => {
  const [title, setTitle] = useState('');

  const [form] = Form.useForm();
  const handleChangeName = (value) => {
    form.setFieldsValue({
      slug: convertToSlug(value),
    });
  };
  const handleDelete = (record: any) => {
    const newdataSource = dataSource.filter(
      (item: any) => item.id !== record.id,
    );
    setDataSource(newdataSource);
  };
  const handleAddList = (record: any) => {
    const newdataSource = [...dataSource];
    const newlistServiceSearch = listServiceSearch.filter(
      (item: any) => item._id !== record._id,
    );
    newdataSource.push({
      id: record._id,
      key: record._id,
      name: record.name,
      code: record.code,
    });
    setDataSource(newdataSource);
    setListServiceSearch(newlistServiceSearch);
  };
  const [listServiceSearch, setListServiceSearch] = useState([]);

  const columns = [
    {
      key: 'sort',
      dataIndex: 'id',
    },
    {
      title: 'Tên dịch vụ',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Mã dịch vụ',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record) => (
        <div className="flex">
          <Button
            type="primary"
            style={{ marginRight: 8 }}
            onClick={() => handleDelete(record)}
          >
            <DeleteOutlined className="relative top-[-2px]" />
          </Button>
        </div>
      ),
    },
  ];

  const columnsSearch = [
    {
      title: 'Tên dịch vụ',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Mã dịch vụ',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record) => (
        <div className="flex">
          <Button
            type="primary"
            style={{ marginRight: 8 }}
            onClick={() => handleAddList(record)}
          >
            <PlusCircleOutlined className="relative top-[-2px]" />
          </Button>
        </div>
      ),
    },
  ];

  const handleSearchServices = () => {
    if (title) {
      handleService
        .GetListServiceSearch({ Name: title })
        .then((response: any) => {
          const { data, statusCode } = response;
          if (statusCode === 200) {
            // lọc
            const listData = data.filter(
              (item) => !dataSource.some((d) => d.id === item._id),
            );
            setListServiceSearch(listData);
          } else {
            notification.error({
              message: 'Lỗi',
              description: 'Có lỗi xảy ra trong quá trình xử lý dữ liệu !!',
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };
  return (
    <Form
      form={form}
      key={JSON.stringify(initialValues)}
      initialValues={initialValues}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      className="mb-10 main-add-form"
    >
      <Form.Item
        label="Tên nhóm dịch vụ"
        name="name"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập tên nhóm dịch vụ',
          },
        ]}
        className="mb-0"
      >
        <Input
          placeholder="Nhập tên nhóm dịch vụ"
          onChange={(e) => handleChangeName(e.target.value)}
        />
      </Form.Item>
      <Form.Item
        label="Đường dẫn bài viết"
        name="slug"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập đường dẫn bài viết',
          },
        ]}
        className="mb-0"
      >
        <Input placeholder="Nhập đường dẫn bài viết" />
      </Form.Item>
      <div className="mb-5 ml-10 font-bold">Thông tin chi tiết</div>
      <div className="mx-10 mb-5">
        <Ckeditor data={content} setData={setContent} folder={'service'} />
      </div>
      <Form.Item
        label="Hiển thị trên booking"
        name="activeBooking"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn hiển thị booking',
          },
        ]}
      >
        <Switch checkedChildren="Mở" unCheckedChildren="Đóng" defaultChecked />
      </Form.Item>
      <Form.Item
        label="Hiển thị trên trang chủ"
        name="showHome"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn trạng thái hiển thị',
          },
        ]}
      >
        <Switch
          checkedChildren="Hiện"
          unCheckedChildren="Không hiện"
          defaultChecked
        />
      </Form.Item>
      <div className="flex justify-between mx-10">
        <div className="w-[45%]">
          <div className="flex">
            <Input
              placeholder="Nhập mã - tên dịch vụ"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <Button type="primary" onClick={handleSearchServices}>
              Tìm kiếm
            </Button>
          </div>
          <Table
            dataSource={listServiceSearch}
            columns={columnsSearch}
            className="w-full mt-5"
          />
        </div>
        <div className="w-[50%]">
          <DndContext
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={onDragEnd}
          >
            <SortableContext
              // rowKey array
              items={dataSource.map((i) => i.key)}
              strategy={verticalListSortingStrategy}
            >
              <Table
                components={{
                  body: {
                    row: Row,
                  },
                }}
                rowKey="key"
                columns={columns}
                dataSource={dataSource}
                pagination={false}
              />
            </SortableContext>
          </DndContext>
        </div>
      </div>
      <div className="mt-10 ml-10 font-bold">Thông tin SEO</div>
      <Form.Item label="Title" name="titleSeo" className="mb-0">
        <Input placeholder="Nhập title SEO" />
      </Form.Item>
      <Form.Item label="Keywork" name="keywordSeo" className="mb-0">
        <Input placeholder="Nhập Keywork SEO" />
      </Form.Item>
      <Form.Item label="Description" name="descriptionSeo" className="mb-0">
        <TextArea placeholder="Nhập mô tả SEO" />
      </Form.Item>
      <div className="flex justify-end">
        <Button type="primary" className="button-submit" htmlType="submit">
          {!initialValues?.config ? 'Tạo mới ' : 'Cập nhật'}
        </Button>
      </div>
    </Form>
  );
};
