import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { notification, Input, Button } from 'antd';
import { handleConfig } from '@services';
import { useNavigate } from 'react-router-dom';
import { breadcrumbConfigChinhSach as breadcrumbItems } from '@components/Breadcrumb';
import UploadCustomV2 from '@components/UploadImage/UploadMono';
import { CategoryForm } from '@components/Form/Category';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';
import UploadMutipleCustom from '@components/UploadImage/UploadMutiple';
import { v4 as uuidv4 } from 'uuid';
import '../index.scss';
import TextArea from 'antd/es/input/TextArea';
import Ckeditor from '@components/Editor/CkeditorEnable';

export const ConfigChinhSach = () => {
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState('');
  const [titleP, setTitleP] = useState<string>('');

  const title = 'Cấu hình giao diện trang chính sách';
  const titleButton = '';
  const routerAdd = '';
  const forlderName = 'home';
  const [listData, setListData] = useState<any>([
    {
      id: uuidv4(),
      title: '',
      content: '',
    },
  ]);
  const handleGetDataDetail = async () => {
    handleConfig
      .GetDetailConfig('CHINHSACH')
      .then((response: any) => {
        const { statusCode, data } = response;
        if (data && data?.content) {
          const contenJson = JSON.parse(data?.content);
          console.log(contenJson, 'contenJson');
          if (contenJson) {
            setImageUrl(contenJson?.image);
            setTitleP(contenJson?.title);
            setListData(contenJson?.content);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    handleGetDataDetail();
  }, []);
  const onFinish = () => {
    const bodyValues = {
      image: imageUrl,
      title: titleP,
      content: listData,
    };
    const body = {
      name: 'CHINHSACH',
      content: JSON.stringify(bodyValues),
    };
    console.log(body, 'body');
    handleConfig
      .UpdateConfig(body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Cập nhật dữ liệu thành công',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  const handleAdd = () => {
    const newlist = [...listData];
    newlist.push({ id: uuidv4(), name: '', note: '', content: '' });
    setListData(newlist);
  };
  const handleDelete = (idToDelete: string) => {
    const updatedList = listData.filter((item) => item.id !== idToDelete);
    setListData(updatedList);
  };

  const handleEdit = (id: string, newValue: any, fieldName: string) => {
    setListData((prevList) => {
      const updatedList = prevList.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            [fieldName]: newValue,
          };
        }
        return item;
      });
      return updatedList;
    });
  };
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="px-5 py-5 mt-5 bg-white mb-[50px]">
        <div className="font-bold">
          1. Ảnh Baner (không quá 3mb, tỉ lệ dài : cao - 1440 : 430 )
        </div>
        <UploadCustomV2
          setImageUrl={setImageUrl}
          urlImage={imageUrl}
          imageClass={ImageCalssEnum.Certificate}
          forlderName={forlderName}
        ></UploadCustomV2>
        <div className="font-bold">2. Tiêu đề</div>
        <div className="flex items-center justify-between">
          <Input
            placeholder="Nội dung"
            className="my-5"
            value={titleP}
            onChange={(e) => setTitleP(e.target.value)}
          ></Input>
        </div>
        <div className="font-bold">3. Nội dung bài viết</div>
        <div className="mb-5 italic">các bài viết về chính sách</div>
        {listData &&
          listData.map((item, index) => (
            <div key={item.id} className="flex mb-5 border-b-[1px] pb-5">
              <div className="">
                <Input
                  placeholder="Tiêu đề"
                  className="mb-2"
                  value={item.title}
                  onChange={(e) => handleEdit(item.id, e.target.value, 'title')}
                />
                <div className="w-[880px]">
                  <Ckeditor
                    data={item.content}
                    setData={(e) => handleEdit(item.id, e, 'content')}
                    folder={'service'}
                  />
                </div>
              </div>
              <Button onClick={() => handleDelete(item.id)} className="ml-1">
                Xoá
              </Button>
            </div>
          ))}
        <div className="flex justify-end mx-10 mt-5 mb-5">
          <Button onClick={handleAdd} type="dashed">
            Thêm
          </Button>
        </div>
        <div className="flex justify-center w-full">
          <Button type="primary" onClick={onFinish}>
            Cập nhật dữ liệu
          </Button>
        </div>
      </div>
    </>
  );
};
