import { instance } from '@configs/instance';

const getListReport = (param: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_MARKETING + `/report/report-list`,
    {
      params: param,
    },
  );
};
export default {
  getListReport,
};
