import { instance } from '@configs/instance';

const getListServices = (param: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_MARKETING + `/service/service-list`,
    {
      params: param,
    },
  );
};

const getListServicesSearch = (param: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_MARKETING + `/service/service-list-search`,
    {
      params: param,
    },
  );
};

const getListGroupServices = (param: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_MARKETING + `/service/group-list`,
    {
      params: param,
    },
  );
};

const getDetailService = (id: string): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_MARKETING + `/service/detail/${id}`,
  );
};

const updateService = (serviceId: string, body: any): Promise<any> => {
  return instance.put(
    process.env.REACT_APP_API_MARKETING + `/service/${serviceId}/update`,
    body,
  );
};

const updateBookingService = (serviceId: string, body: any): Promise<any> => {
  return instance.patch(
    process.env.REACT_APP_API_MARKETING +
      `/service/${serviceId}/update-booking`,
    body,
  );
};
const updateStatusService = (serviceId: string, body: any): Promise<any> => {
  return instance.patch(
    process.env.REACT_APP_API_MARKETING + `/service/${serviceId}/update-status`,
    body,
  );
};

const createGroupService = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_MARKETING + `/service/group-service/create`,
    body,
  );
};
const getDetailGroupService = (id: string): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_MARKETING + `/service/group-service/${id}`,
  );
};
const updateGroupService = (id: string, body: any): Promise<any> => {
  return instance.put(
    process.env.REACT_APP_API_MARKETING + `/service/group-service/${id}`,
    body,
  );
};

const updateGroupServiceOnTop = (id: string): Promise<any> => {
  return instance.patch(
    process.env.REACT_APP_API_MARKETING + `/service/group-service/${id}`,
  );
};

// Phiên bản mới
const getListGroupServicesV2 = (param: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_MARKETING + `/service/v2/group-list`,
    {
      params: param,
    },
  );
};
const createGroupV2 = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_MARKETING + `/service/v2/create-group`,
    body,
  );
};

const updateGroupV2 = (body: any, id: any): Promise<any> => {
  return instance.put(
    process.env.REACT_APP_API_MARKETING + `/service/v2/update-group/${id}`,
    body,
  );
};

const deleteGroupV2 = (id: any): Promise<any> => {
  return instance.delete(
    process.env.REACT_APP_API_MARKETING + `/service/v2/delete-group/${id}`,
  );
};

const onTopGroupV2 = (id: any): Promise<any> => {
  return instance.patch(
    process.env.REACT_APP_API_MARKETING + `/service/v2/ontop-group/${id}`,
  );
};

const detailGroupV2 = (id: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_MARKETING + `/service/v2/detail-group/${id}`,
  );
};

const droplistGroupV2 = (): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_MARKETING + `/service/v2/drop-list-group`,
  );
};

// chỉnh sửa dịch vụ bản mới
const updateServiceV2 = (body: any, id: any): Promise<any> => {
  return instance.put(
    process.env.REACT_APP_API_MARKETING + `/service/v2/update/${id}`,
    body,
  );
};

const deleteServiceV2 = (id: any): Promise<any> => {
  return instance.delete(
    process.env.REACT_APP_API_MARKETING + `/service/v2/delete/${id}`,
  );
};

const onTopServiceV2 = (id: any): Promise<any> => {
  return instance.patch(
    process.env.REACT_APP_API_MARKETING + `/service/v2/ontop/${id}`,
  );
};

const detailServiceV2 = (id: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_MARKETING + `/service/v2/detail/${id}`,
  );
};

const getListServicesV2 = (param: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_MARKETING + `/service/v2/list`,
    {
      params: param,
    },
  );
};
export default {
  getListServices,
  getDetailService,
  updateService,
  getListGroupServices,
  getListServicesSearch,
  createGroupService,
  getDetailGroupService,
  updateGroupService,
  updateBookingService,
  updateStatusService,
  updateGroupServiceOnTop,
  getListGroupServicesV2,
  createGroupV2,
  updateGroupV2,
  deleteGroupV2,
  onTopGroupV2,
  detailGroupV2,
  droplistGroupV2,
  updateServiceV2,
  deleteServiceV2,
  onTopServiceV2,
  detailServiceV2,
  getListServicesV2,
};
