import UploadEndpoint from '@api/upload';

export const handleUploadImage = async (
  file: File,
  folder: string,
): Promise<any | Error> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('folder', folder);
  try {
    const { status, data } = await UploadEndpoint.uploadV1(formData);
    if (status === 200) {
      return data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};
