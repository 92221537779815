import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { notification, Button, Input } from 'antd';
import { handleNews } from '@services';
import { useNavigate, useParams } from 'react-router-dom';
import { breadcrumbDetailGroupNews as breadcrumbItems } from '@components/Breadcrumb';
import { removeNullAndUndefined } from '@utils/Functions/configArray';
import { v4 as uuidv4 } from 'uuid';
import { convertToSlug } from '@utils/Functions/RemoveVietnamese';
import {
  convertStringToList,
  convertListToString,
} from '@utils/Functions/configArray';
import { GroupNewsForm } from '@components/Form/GroupNews';
import '../../index.scss';

export const DetailGroupNews = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState<any>({ config: true });
  const [imageUrl, setImageUrl] = useState([]);
  const [idSQL, setIdSQL] = useState();
  const [listVoucher, setListVoucher] = useState([]);
  const [title, setTitle] = useState('Chỉnh sửa nhóm bài viết tin tức');
  const titleButton = '';
  const routerAdd = '';
  const forlderName = 'category';

  const handleGetDataDetail = async (id) => {
    handleNews
      .DetailGroupNews(id)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setInitialValues({
            name: data.name,
            active: data.active,
            description: data.description,
            config: true,
          });
          setTitle(data?.name);
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (id) {
      handleGetDataDetail(id);
    }
  }, [id]);
  const onFinish = (values: any) => {
    const body = {
      name: values.name,
      active: values.active,
      description: values.description,
    };
    console.log(body, 'body');
    handleNews
      .UpdateGroupNews(body, id)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          handleGetDataDetail(id);
          notification.success({
            message: 'Thành công',
            description: 'Câp nhật nhóm tin tức thành công',
          });
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Có lỗi xảy ra trong quá trình xử lý dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />

      <div className="px-10 py-10 mt-5 bg-white">
        <GroupNewsForm
          initialValues={initialValues}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        />
      </div>
    </>
  );
};
