import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { notification } from 'antd';
import { handleNews } from '@services';
import { useNavigate } from 'react-router-dom';
import { breadcrumbAddNews as breadcrumbItems } from '@components/Breadcrumb';
import UploadCustomV2 from '@components/UploadImage/UploadMono';
import { NewsForm } from '@components/Form/News';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';
import '../../index.scss';

export const AddNews = () => {
  const navigate = useNavigate();
  const initialValues = {
    active: true,
    indexing: 0,
    config: false,
  };

  const [imageUrl, setImageUrl] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const title = 'Tạo bài viết tin tức';
  const titleButton = '';
  const routerAdd = '';
  const forlderName = 'news';

  const onFinish = (values: any) => {
    const body = {
      name: values.name,
      slug: values.slug,
      description: values.description,
      groupId: values.groupId,
      active: values.active,
      indexing: values.indexing,
      content: content,
      seo: {
        title: values.titleSeo,
        keyword: values.keywordSeo,
        description: values.descriptionSeo,
      },
      image: imageUrl,
    };
    console.log(body, 'body');
    handleNews
      .CreateNews(body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Bạn đã tạo thành công bài viết tin tức',
          });
          navigate('/news/list?pageSize=10&pageIndex=1');
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Có lỗi xảy ra trong quá trình xử lý dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="px-10 py-10 mt-5 bg-white">
        <div className="flex flex-col items-center">
          <h4 className="font-bold">Ảnh tiêu đề bài viết</h4>
          <UploadCustomV2
            setImageUrl={setImageUrl}
            urlImage={imageUrl}
            imageClass={ImageCalssEnum.Certificate}
            forlderName={forlderName}
          ></UploadCustomV2>
        </div>
        <div className="">
          <NewsForm
            initialValues={initialValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            content={content}
            setContent={setContent}
          />
        </div>
      </div>
    </>
  );
};
