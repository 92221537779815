import React, { useState, useEffect } from 'react';
import { Button, Input, Form, Switch, InputNumber, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { removeVietnamese } from '@utils/Functions/RemoveVietnamese';
import TextArea from 'antd/es/input/TextArea';
import Ckeditor from '@components/Editor/CkeditorEnable';
import UploadMono from '@components/UploadImage/UploadV3';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';
import { v4 as uuidv4 } from 'uuid';
import { convertToSlug } from '@utils/Functions/RemoveVietnamese';
import { handleService } from '@services';
export const ServiceV2Form = ({
  initialValues,
  onFinish,
  onFinishFailed,
  content,
  setContent,
  listData,
  setListData,
}) => {
  const [form] = Form.useForm();
  const [listGroup, setListGroup] = useState([]);
  const handleGetListGroup = async () => {
    handleService
      .DroplistGroupV2()
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        console.log(data, 'data');
        setListGroup(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    handleGetListGroup();
  }, []);
  const handleAdd = () => {
    const newlist = [...listData];
    newlist.push({ id: uuidv4(), image: '', title: '', text: '' });
    setListData(newlist);
  };
  const handleDelete = (idToDelete: string) => {
    const updatedList = listData.filter((item) => item.id !== idToDelete);
    setListData(updatedList);
  };

  const handleEdit = (id: string, newValue: string, fieldName: string) => {
    setListData((prevList) => {
      const updatedList = prevList.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            [fieldName]: newValue,
          };
        }
        return item;
      });
      return updatedList;
    });
  };
  const handleChangeName = (value) => {
    form.setFieldsValue({
      slug: convertToSlug(value),
    });
  };
  const forlderName = 'services';

  return (
    <Form
      form={form}
      key={JSON.stringify(initialValues)}
      initialValues={initialValues}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      className="mb-10 main-add-form"
    >
      <Form.Item
        name="name"
        label="Tiêu đề dịch vụ"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập tên dịch vụ',
          },
        ]}
        className="w-full"
      >
        <Input
          placeholder="Nhập tên dịch vụ"
          onChange={(e) => handleChangeName(e.target.value)}
        />
      </Form.Item>
      <Form.Item
        label="Đường dẫn bài viết"
        name="slug"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập đường dẫn bài viết',
          },
        ]}
        className="mb-0"
      >
        <Input placeholder="Nhập đường dẫn bài viết" />
      </Form.Item>
      <Form.Item
        label="Nhóm dịch vụ"
        name="groupId"
        className="mb-0"
        rules={[
          {
            required: true,
            message: 'Vui lòng lựa chọn nhóm dịch vụ',
          },
        ]}
      >
        <Select
          showSearch
          placeholder="Lựa chọn nhóm dịch vụ"
          options={listGroup}
          optionFilterProp="children"
          filterOption={(input, option: any) =>
            removeVietnamese(option?.label ?? '').includes(
              removeVietnamese(input),
            )
          }
        ></Select>
      </Form.Item>
      <Form.Item
        label="Giá/ Thời gian phục vụ"
        name="config"
        rules={[
          {
            required: true,
            message: '',
          },
        ]}
        className="mb-0"
      >
        <div className="flex justify-between mg-bttom-0">
          <Form.Item
            name="price"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập giá dịch vụ',
              },
            ]}
            className="mg-bttom-0 w-[48%]"
          >
            <InputNumber
              placeholder="Nhập giá dịch vụ"
              className="w-full"
              min={0}
            />
          </Form.Item>
          <Form.Item
            name="time"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập thời gian dịch vụ',
              },
            ]}
            className="mg-bttom-0 w-[48%]"
          >
            <Input placeholder="Nhập thời gian dịch vụ" />
          </Form.Item>
        </div>
      </Form.Item>
      <Form.Item
        label="Trạng thái"
        name="active"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn trạng thái hoạt động',
          },
        ]}
      >
        <Switch
          checkedChildren="Hiển thị"
          unCheckedChildren="Tạm ẩn"
          defaultChecked
        />
      </Form.Item>
      <Form.Item label="Thứ hạng hiển thị" name="indexing" className="mb-0">
        <div className="flex mg-bttom-0">
          <Form.Item label="" name="indexing" className="mb-0">
            <InputNumber min={0} />
          </Form.Item>
          <span className="ml-5 italic text-[#1677FF] mt-1">
            Dùng để ưu tiên hiển thị dịch vụ trong nhóm
          </span>
        </div>
      </Form.Item>
      <Form.Item
        label="Thông tin nổi bật"
        name="description"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập thông tin nổi bật',
          },
        ]}
      >
        <TextArea placeholder="Nhập thông tin nổi bật" />
      </Form.Item>
      <Form.Item label="Thông tin miêu tả" name="content">
        <TextArea placeholder="Nhập thông tin miêu tả dịch vụ" />
      </Form.Item>

      <div className="mb-2 font-bold">Thông tin dịch vụ</div>
      {listData &&
        listData.map((item, index) => (
          <div key={item.id} className="border-t-[1px] pb-5 pt-5">
            <div className="flex items-center">
              <UploadMono
                setImageUrl={(value) => handleEdit(item.id, value, 'image')}
                urlImage={item.image}
                imageClass={ImageCalssEnum.SquarePhoto}
                forlderName={forlderName}
              ></UploadMono>

              <Input
                placeholder="Nhập tiêu đề (Công dụng, ưu điểm, ...)"
                className="mx-5"
                value={item.title}
                onChange={(e) => handleEdit(item.id, e.target.value, 'title')}
              />
              <Button onClick={() => handleDelete(item.id)} className="ml-1">
                Xoá
              </Button>
            </div>
            <Ckeditor
              data={item.text}
              setData={(value: any) => handleEdit(item.id, value, 'text')}
              folder={'service'}
            />
          </div>
        ))}
      <div className="flex justify-end mt-5 mb-5">
        <Button onClick={handleAdd} type="primary">
          Thêm
        </Button>
      </div>
      <div className="ml-10 font-bold">Thông tin SEO</div>
      <Form.Item label="Title" name="titleSeo" className="mb-0">
        <Input placeholder="Nhập title SEO" />
      </Form.Item>
      <Form.Item label="Keywork" name="keywordSeo" className="mb-0">
        <Input placeholder="Nhập Keywork SEO" />
      </Form.Item>
      <Form.Item label="Description" name="descriptionSeo" className="mb-0">
        <TextArea placeholder="Nhập mô tả SEO" />
      </Form.Item>
      <div className="flex justify-end">
        <Button type="primary" className="button-submit" htmlType="submit">
          {!initialValues?.config ? 'Tạo mới ' : 'Cập nhật'}
        </Button>
      </div>
    </Form>
  );
};
