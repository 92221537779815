import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { notification, Input, Button } from 'antd';
import { handleConfig } from '@services';
import { useNavigate } from 'react-router-dom';
import { breadcrumbConfigHome as breadcrumbItems } from '@components/Breadcrumb';
import UploadCustomV2 from '@components/UploadImage/UploadMono';
import { CategoryForm } from '@components/Form/Category';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';
import UploadMutipleCustom from '@components/UploadImage/UploadMutiple';
import { v4 as uuidv4 } from 'uuid';
import '../index.scss';
import TextArea from 'antd/es/input/TextArea';

export const ConfigHome = () => {
  const navigate = useNavigate();
  const [imageUrl1, setImageUrl1] = useState([]);
  const [imageUrl2, setImageUrl2] = useState<string>('');
  const [imageUrl3, setImageUrl3] = useState([]);
  const [content1, setContent1] = useState<string>('');
  const [content2, setContent2] = useState<string>('');
  const [content3, setContent3] = useState<string>('');

  const [textBooking, setTextBooking] = useState<string>('');
  const [textBookingH, setTextBookingH] = useState<string>('');

  const [text1, setText1] = useState<string>('');
  const [text1H, setText1H] = useState<string>('');
  const [text2, setText2] = useState<string>('');
  const [text2H, setText2H] = useState<string>('');
  const [text3, setText3] = useState<string>('');
  const [text3H, setText3H] = useState<string>('');
  const [contentX, setContentX] = useState<string>('');
  const [contentXH, setContentXH] = useState<string>('');
  const [titleCus, setTitleCus] = useState<string>('');
  const title = 'Cấu hình giao diện trang chủ';
  const titleButton = '';
  const routerAdd = '';
  const forlderName = 'home';
  const [listData, setListData] = useState<any>([
    {
      id: uuidv4(),
      name: '',
      note: '',
      content: '',
    },
  ]);
  const handleGetDataDetail = async () => {
    handleConfig
      .GetDetailConfig('HOME')
      .then((response: any) => {
        const { statusCode, data } = response;
        if (data && data?.content) {
          const contenJson = JSON.parse(data?.content);
          console.log(contenJson, 'contenJson');
          if (contenJson?.content1 && contenJson?.content1.length > 0) {
            const listImageParse = contenJson?.content1?.map((item, index) => {
              return {
                uid: (index + 1).toString(),
                lastModified: null,
                lastModifiedDate: '',
                name: item,
                size: 490015,
                type: 'image/png',
                percent: 100,
                originFileObj: {
                  uid: index + 1,
                  name: item,
                },
                status: 'done',
                response: item,
                thumbUrl: item,
                xhr: undefined,
              };
            });
            setImageUrl1(listImageParse);
          }
          if (
            contenJson?.content4 &&
            contenJson?.content4?.image &&
            contenJson?.content4?.image?.length > 0
          ) {
            const listImageParse = contenJson?.content4?.image?.map(
              (item, index) => {
                return {
                  uid: (index + 1).toString(),
                  lastModified: null,
                  lastModifiedDate: '',
                  name: item,
                  size: 49001500,
                  type: 'image/png',
                  percent: 100,
                  originFileObj: {
                    uid: index + 1,
                    name: item,
                  },
                  status: 'done',
                  response: item,
                  thumbUrl: item,
                  xhr: undefined,
                };
              },
            );
            setImageUrl3(listImageParse);
          }
          setTextBooking(contenJson?.content6?.text);
          setTextBookingH(contenJson?.content6?.textH);

          setImageUrl2(contenJson?.content2?.image);
          setContent1(contenJson?.content2?.conten1);
          setContent2(contenJson?.content2?.conten2);
          setContent3(contenJson?.content2?.conten3);

          setText1(contenJson?.content3?.slide1?.text);
          setText1H(contenJson?.content3?.slide1?.textH);
          setText2(contenJson?.content3?.slide2?.text);
          setText2H(contenJson?.content3?.slide2?.textH);
          setText3(contenJson?.content3?.slide3?.text);
          setText3H(contenJson?.content3?.slide3?.textH);

          setContentX(contenJson?.content4?.text);
          setContentXH(contenJson?.content4?.textH);

          setTitleCus(contenJson?.content5?.title);
          if (contenJson?.content5 && contenJson?.content5?.data) {
            setListData(contenJson?.content5?.data);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    handleGetDataDetail();
  }, []);
  const onFinish = () => {
    const newListImages1 = imageUrl1.map((item) => {
      return item.response;
    });
    const newListImages3 = imageUrl3.map((item) => {
      return item.response;
    });
    console.log(newListImages3, 'newListImages3');
    const bodyValues = {
      content1: newListImages1,
      content6: {
        text: textBooking,
        textH: textBookingH,
      },
      content2: {
        image: imageUrl2,
        conten1: content1,
        conten2: content2,
        conten3: content3,
      },
      content3: {
        slide1: { text: text1, textH: text1H },
        slide2: { text: text2, textH: text2H },
        slide3: { text: text3, textH: text3H },
      },
      content4: {
        image: newListImages3,
        text: contentX,
        textH: contentXH,
      },
      content5: {
        title: titleCus,
        data: listData,
      },
    };
    const body = {
      name: 'HOME',
      content: JSON.stringify(bodyValues),
    };
    console.log(body, 'body');
    handleConfig
      .UpdateConfig(body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Cập nhật dữ liệu thành công',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  const handleAdd = () => {
    const newlist = [...listData];
    newlist.push({ id: uuidv4(), name: '', note: '', content: '' });
    setListData(newlist);
  };
  const handleDelete = (idToDelete: string) => {
    const updatedList = listData.filter((item) => item.id !== idToDelete);
    setListData(updatedList);
  };

  const handleEdit = (id: string, newValue: string, fieldName: string) => {
    setListData((prevList) => {
      const updatedList = prevList.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            [fieldName]: newValue,
          };
        }
        return item;
      });
      return updatedList;
    });
  };
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="px-5 py-5 mt-5 bg-white mb-[50px]">
        <div className="font-bold">
          1. Ảnh Slide Baner trang chủ (không quá 3mb, tỉ lệ dài : cao - 1440 :
          650 )
        </div>
        <UploadMutipleCustom
          fileList={imageUrl1}
          setFileList={setImageUrl1}
          forlderName={forlderName}
        />
        <div className="font-bold">2. Nội dung Text trong Hộp đặt lịch</div>
        <div className="my-5">
          <div className="flex items-center justify-between">
            <Input
              placeholder="Đặt lịch ngay"
              className="mr-5"
              value={textBooking}
              onChange={(e) => setTextBooking(e.target.value)}
            ></Input>
            <Input
              placeholder="Nhận ngay ưu đãi"
              value={textBookingH}
              onChange={(e) => setTextBookingH(e.target.value)}
            ></Input>
          </div>
        </div>
        <div className="font-bold">
          3. Nội dung Chào mừng (Ảnh không quá 3mb, tỉ lệ dài : cao - 820 : 330
          )
        </div>
        <div className="flex items-center justify-between">
          <div>
            <Input
              placeholder="Nội dung"
              value={content1}
              onChange={(e) => setContent1(e.target.value)}
            ></Input>
            <Input
              placeholder="Nội dung hightLight"
              className="my-3"
              value={content2}
              onChange={(e) => setContent2(e.target.value)}
            ></Input>
            <Input
              placeholder="Nội dung"
              value={content3}
              onChange={(e) => setContent3(e.target.value)}
            ></Input>
          </div>
          <UploadCustomV2
            setImageUrl={setImageUrl2}
            urlImage={imageUrl2}
            imageClass={ImageCalssEnum.Certificate}
            forlderName={forlderName}
          ></UploadCustomV2>
        </div>
        <div className="font-bold">4. Nội dung Text chạy ngang màn hình</div>
        <div className="mt-5">
          <div className="flex items-center justify-between">
            <Input
              placeholder="Dòng chữ thứ 1"
              className="mr-5"
              value={text1}
              onChange={(e) => setText1(e.target.value)}
            ></Input>
            <Input
              placeholder="Dòng chữ thứ 1 HighLight"
              value={text1H}
              onChange={(e) => setText1H(e.target.value)}
            ></Input>
          </div>
          <div className="flex items-center justify-between my-3">
            <Input
              placeholder="Dòng chữ thứ 2"
              className="mr-5"
              value={text2}
              onChange={(e) => setText2(e.target.value)}
            ></Input>
            <Input
              placeholder="Dòng chữ thứ 2 HighLight"
              value={text2H}
              onChange={(e) => setText2H(e.target.value)}
            ></Input>
          </div>
          <div className="flex items-center justify-between">
            <Input
              placeholder="Dòng chữ thứ 3"
              className="mr-5"
              value={text3}
              onChange={(e) => setText3(e.target.value)}
            ></Input>
            <Input
              placeholder="Dòng chữ thứ 3 HighLight"
              value={text3H}
              onChange={(e) => setText3H(e.target.value)}
            ></Input>
          </div>
        </div>
        <div className="mt-5 font-bold">
          5. Ảnh Slide giữa trang chủ & content (không quá 3mb, tỉ lệ dài : cao
          - 1300 : 400 )
        </div>
        <UploadMutipleCustom
          fileList={imageUrl3}
          setFileList={setImageUrl3}
          forlderName={forlderName}
        />
        <div>
          <Input
            placeholder="Nội dung hightLight"
            className="mb-3"
            value={contentX}
            onChange={(e) => setContentX(e.target.value)}
          ></Input>
          <Input
            placeholder="Nội dung"
            value={contentXH}
            onChange={(e) => setContentXH(e.target.value)}
          ></Input>
        </div>
        <div className="mt-5 font-bold">
          6. Cụm thông tin Khách hàng phản hồi về Facial Bar
        </div>
        <Input
          placeholder="Nhập tiêu đề"
          className="my-3"
          value={titleCus}
          onChange={(e) => setTitleCus(e.target.value)}
        ></Input>
        <div className="mb-5 italic">Thông tin khách hàng phản hồi</div>
        {listData &&
          listData.map((item, index) => (
            <div key={item.id} className="flex mx-10 border-b-[1px]  mb-5 pb-5">
              <div className="w-full ml-10">
                <Input
                  placeholder="Tên khách hàng"
                  className="mb-2"
                  value={item.name}
                  onChange={(e) => handleEdit(item.id, e.target.value, 'name')}
                />
                <Input
                  placeholder="Nghề nghiệp/ chức vụ"
                  className="mb-2"
                  value={item.note}
                  onChange={(e) => handleEdit(item.id, e.target.value, 'note')}
                />
                <TextArea
                  placeholder="Nhập mô tả"
                  value={item.content}
                  onChange={(e) =>
                    handleEdit(item.id, e.target.value, 'content')
                  }
                />
              </div>
              <Button onClick={() => handleDelete(item.id)} className="ml-1">
                Xoá
              </Button>
            </div>
          ))}
        <div className="flex justify-end mx-10 mt-5 mb-5">
          <Button onClick={handleAdd} type="dashed">
            Thêm
          </Button>
        </div>
        <div className="flex justify-center w-full">
          <Button type="primary" onClick={onFinish}>
            Cập nhật dữ liệu
          </Button>
        </div>
      </div>
    </>
  );
};
