import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { notification, Button, Input } from 'antd';
import { handleNews } from '@services';
import { useNavigate, useParams } from 'react-router-dom';
import { breadcrumbDetailNews as breadcrumbItems } from '@components/Breadcrumb';
import { removeNullAndUndefined } from '@utils/Functions/configArray';
import { v4 as uuidv4 } from 'uuid';
import { convertToSlug } from '@utils/Functions/RemoveVietnamese';
import {
  convertStringToList,
  convertListToString,
} from '@utils/Functions/configArray';
import { NewsForm } from '@components/Form/News';
import '../../index.scss';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';
import UploadCustomV2 from '@components/UploadImage/UploadMono';

export const DetailNews = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState<any>({ config: true });
  const [imageUrl, setImageUrl] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [title, setTitle] = useState('Chỉnh sửa bài viết tin tức');
  const titleButton = '';
  const routerAdd = '';
  const forlderName = 'category';

  const handleGetDataDetail = async (id) => {
    handleNews
      .DetailNews(id)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setInitialValues({
            name: data.name,
            slug: data.slug,
            description: data.description,
            groupId: data.groupId,
            active: data.active,
            indexing: data.indexing,
            titleSeo: data.seo?.title,
            keywordSeo: data.seo?.keyword,
            descriptionSeo: data.seo?.description,
            config: true,
          });
          setTitle(data?.name);
          setContent(data?.content);
          setImageUrl(data?.image);
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (id) {
      handleGetDataDetail(id);
    }
  }, [id]);
  const onFinish = (values: any) => {
    const body = {
      name: values.name,
      slug: values.slug,
      description: values.description,
      groupId: values.groupId,
      active: values.active,
      indexing: values.indexing,
      content: content,
      seo: {
        title: values.titleSeo,
        keyword: values.keywordSeo,
        description: values.descriptionSeo,
      },
      image: imageUrl,
    };
    console.log(body, 'body');
    handleNews
      .UpdateNews(body, id)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Bạn đã cập nhật thành công bài viết tin tức',
          });
          handleGetDataDetail(id);
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Có lỗi xảy ra trong quá trình xử lý dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />

      <div className="px-10 py-10 mt-5 bg-white">
        <div className="flex flex-col items-center">
          <h4 className="font-bold">Ảnh tiêu đề bài viết</h4>
          <UploadCustomV2
            setImageUrl={setImageUrl}
            urlImage={imageUrl}
            imageClass={ImageCalssEnum.Certificate}
            forlderName={forlderName}
          ></UploadCustomV2>
        </div>
        <div className="">
          <NewsForm
            initialValues={initialValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            content={content}
            setContent={setContent}
          />
        </div>
      </div>
    </>
  );
};
