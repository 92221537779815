import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { notification, Button, Input } from 'antd';
import { handleCategory, handleService, handlePromotion } from '@services';
import { useNavigate, useParams } from 'react-router-dom';
import { breadcrumbDetailServiceV2 as breadcrumbItems } from '@components/Breadcrumb';
import UploadMutipleCustom from '@components/UploadImage/UploadMutiple';
import { ServiceV2Form } from '@components/Form/ServiceV2';
import { removeNullAndUndefined } from '@utils/Functions/configArray';
import { v4 as uuidv4 } from 'uuid';
import { convertToSlug } from '@utils/Functions/RemoveVietnamese';
import {
  convertStringToList,
  convertListToString,
} from '@utils/Functions/configArray';
import { Link } from 'react-router-dom';
import UploadCustomV2 from '@components/UploadImage/UploadMono';
import '../../index.scss';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';

export const DetailServiceV2 = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [content, setContent] = useState<any>('');
  const [initialValues, setInitialValues] = useState<any>({ config: true });
  const [listData, setListData] = useState<any>([
    {
      id: uuidv4(),
      image: '',
      title: '',
      text: '',
    },
  ]);

  const [idSQL, setIdSQL] = useState();
  const [imageUrl, setImageUrl] = useState<string>('');
  const [title, setTitle] = useState('Chỉnh sửa thông tin dịch vụ');
  const titleButton = '';
  const routerAdd = '';
  const forlderName = 'category';

  const [voucherId, setVoucherId] = useState<any>();
  const handleGetDataDetail = async (serviceid) => {
    handleService
      .DetailServiceV2(serviceid)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setTitle(data.name);
          setInitialValues({
            name: data.name,
            slug: data.slug ? data.slug : convertToSlug(data.name),
            groupId: data.groupId,
            indexing: data.indexing,
            price: data.price,
            active: data.active,
            category_idSQL: data.category_idSQL,
            description: data.description,
            time: data.time,
            titleSeo: data.seo?.title,
            keywordSeo: data.seo?.keyword,
            descriptionSeo: data.seo?.description,
            content: data?.content,
            config: true,
          });
          if (data.image) {
            setImageUrl(data.image);
          }
          if (data.procedure) {
            setListData(data.procedure);
          }
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (id) {
      handleGetDataDetail(id);
    }
  }, [id]);

  const onFinish = (values: any) => {
    const body = {
      name: values.name,
      slug: values.slug,
      groupId: values.groupId,
      indexing: values.indexing,
      price: values.price,
      time: values.time,
      seo: {
        title: values.titleSeo,
        keyword: values.keywordSeo,
        description: values.descriptionSeo,
      },
      procedure: listData,
      content: values?.content,
      active: values.active,
      description: values.description,
      image: imageUrl,
    };
    console.log(body, 'body');
    handleService
      .UpdateServiceV2(body, id)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          handleGetDataDetail(id);
          notification.success({
            message: 'Thành công',
            description: 'Câp nhật thông tin dịch vụ thành công',
          });
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Có lỗi xảy ra trong quá trình xử lý dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  const handleChoseVoucher = (id) => {
    if (id === voucherId) {
      setVoucherId(null);
    } else {
      setVoucherId(id);
    }
  };
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="px-10 py-10 mt-5 bg-white">
        <div className="flex flex-col items-center">
          <h4 className="font-bold">Ảnh dịch vụ</h4>
          <UploadCustomV2
            setImageUrl={setImageUrl}
            urlImage={imageUrl}
            imageClass={ImageCalssEnum.CardPhoto}
            forlderName={forlderName}
          ></UploadCustomV2>
        </div>
        <div className="mt-[0px]">
          <ServiceV2Form
            initialValues={initialValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            content={content}
            setContent={setContent}
            listData={listData}
            setListData={setListData}
          />
        </div>
      </div>
    </>
  );
};
