// authSlice.js
import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
const accessToken = Cookies.get('accessToken');

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    accessToken: accessToken ? accessToken : null,
  },
  reducers: {
    setToken: (state, action) => {
      state.accessToken = action.payload;
    },
    clearToken: (state) => {
      state.accessToken = null;
    },
  },
});

export const { setToken, clearToken } = authSlice.actions;
export const selectToken = (state) => state.auth.token;
export default authSlice.reducer;
