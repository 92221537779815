import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { notification } from 'antd';
import { handleService } from '@services';
import { useNavigate } from 'react-router-dom';
import { breadcrumAddGroupServiceV2 as breadcrumbItems } from '@components/Breadcrumb';
import UploadCustomV2 from '@components/UploadImage/UploadMono';
import { GroupServicesV2Form } from '@components/Form/GroupServicesV2';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';

export const AddGroupServicesV2 = () => {
  const navigate = useNavigate();
  const initialValues = {
    show_home: false,
    minium: false,
    active: true,
    config: false,
  };

  const title = 'Tạo mới nhóm dịch vụ';
  const titleButton = '';
  const routerAdd = '';

  const onFinish = (values: any) => {
    const body = {
      name: values.name,
      active: values.activeBooking,
      show_home: values.show_home,
      minium: values.minium,
      description: values.description,
      content: {
        text: values.text,
        hightlight: values.hightlight,
      },
    };
    console.log(body, 'body');
    handleService
      .CreateGroupV2(body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Bạn đã tạo thành công nhóm dịch vụ mới',
          });
          navigate('/v2-group-service/list?pageSize=10&pageIndex=1');
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Có lỗi xảy ra trong quá trình xử lý dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="main main-add">
        <div className="w-full pt-10 bg-white">
          <GroupServicesV2Form
            initialValues={initialValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          />
        </div>
      </div>
    </>
  );
};
