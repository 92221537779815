import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import {
  setupInstance,
  setupInstanceExcel,
  // setupInstanceUploadS3,
} from '@configs/Interceptors';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

setupInstance(store);
setupInstanceExcel(store);
// setupInstanceUploadS3(store);
