// dataStorage.ts

export const saveDataToStorage = (title: string, data: any): void => {
  const storedData = JSON.parse(localStorage.getItem(title) || '{}');

  // Lưu dữ liệu vào localStorage với key là title
  storedData.data = data;

  localStorage.setItem(title, JSON.stringify(storedData));
};

export const getDataFromStorage = (title: string): any | null => {
  const storedData = JSON.parse(localStorage.getItem(title) || '{}');

  // Lấy dữ liệu từ localStorage với key là title
  const data = storedData.data;

  // Trả về dữ liệu nếu có, ngược lại trả về rỗng
  return data !== undefined ? data : [];
};
