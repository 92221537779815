import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { notification, Input, Button } from 'antd';
import { handleConfig } from '@services';
import { useNavigate } from 'react-router-dom';
import { breadcrumbConfigCauChuyenThuongHieu as breadcrumbItems } from '@components/Breadcrumb';
import UploadCustomV2 from '@components/UploadImage/UploadMono';
import { CategoryForm } from '@components/Form/Category';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';
import UploadMutipleCustom from '@components/UploadImage/UploadMutiple';
import { v4 as uuidv4 } from 'uuid';
import '../index.scss';
import TextArea from 'antd/es/input/TextArea';
import Ckeditor from '@components/Editor/CkeditorEnable';

export const ConfigCauChuyenThuongHieu = () => {
  const navigate = useNavigate();
  const [imageUrl1, setImageUrl1] = useState('');
  const [content1, setContent1] = useState('');
  const [titleContent2, setTitleContent2] = useState('');
  const [textContent2, setTextContent2] = useState('');
  const [textHContent2, setTextHContent2] = useState('');
  const [mainContent2, setMainContent2] = useState('');

  const [titleContent3, setTitleContent3] = useState('');
  const [textContent3, setTextContent3] = useState('');
  const [textHContent3, setTextHContent3] = useState('');
  const [mainContent3, setMainContent3] = useState('');
  const [imageContent3, setImageContent3] = useState('');

  const [titleContent4, setTitleContent4] = useState('');
  const [textContent4, setTextContent4] = useState('');
  const [textHContent4, setTextHContent4] = useState('');
  const [mainContent4, setMainContent4] = useState('');
  const [imageContent4, setImageContent4] = useState('');

  const [titleContent5, setTitleContent5] = useState('');
  const [textContent5, setTextContent5] = useState('');
  const [textHContent5, setTextHContent5] = useState('');
  const [mainContent5, setMainContent5] = useState('');

  const [textContent6, setTextContent6] = useState('');
  const [textHContent6, setTextHContent6] = useState('');
  const [mainContent6, setMainContent6] = useState('');
  const [imageContent6, setImageContent6] = useState('');

  const title = 'Cấu hình giao diện trang câu chuyện thương hiệu';
  const titleButton = '';
  const routerAdd = '';
  const forlderName = 'home';
  const [listData, setListData] = useState<any>([
    {
      id: uuidv4(),
      name: '',
      note: '',
      content: '',
    },
  ]);
  const handleGetDataDetail = async () => {
    handleConfig
      .GetDetailConfig('CAUCHUYENTHUONGHIEU')
      .then((response: any) => {
        const { statusCode, data } = response;
        if (data && data?.content) {
          const contenJson = JSON.parse(data?.content);
          console.log(contenJson, 'contenJson');
          if (contenJson) {
            setImageUrl1(contenJson?.content1?.image);
            setContent1(contenJson?.content1?.content);

            setTitleContent2(contenJson?.content2?.title);
            setTextContent2(contenJson?.content2?.text);
            setTextHContent2(contenJson?.content2?.textH);
            setMainContent2(contenJson?.content2?.content);

            setTitleContent3(contenJson?.content3?.title);
            setImageContent3(contenJson?.content3?.image);
            setTextContent3(contenJson?.content3?.text);
            setTextHContent3(contenJson?.content3?.textH);
            setMainContent3(contenJson?.content3?.content);

            setTitleContent4(contenJson?.content4?.title);
            setImageContent4(contenJson?.content4?.image);
            setTextContent4(contenJson?.content4?.text);
            setTextHContent4(contenJson?.content4?.textH);
            setMainContent4(contenJson?.content4?.content);

            setTitleContent5(contenJson?.content5?.title);
            setTextContent5(contenJson?.content5?.text);
            setTextHContent5(contenJson?.content5?.textH);
            setMainContent5(contenJson?.content5?.content);

            setImageContent6(contenJson?.content6?.image);
            setTextContent6(contenJson?.content6?.text);
            setTextHContent6(contenJson?.content6?.textH);
            setMainContent6(contenJson?.content6?.content);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    handleGetDataDetail();
  }, []);
  const onFinish = () => {
    const bodyValues = {
      content1: {
        image: imageUrl1,
        content: content1,
      },
      content2: {
        title: titleContent2,
        text: textContent2,
        textH: textHContent2,
        content: mainContent2,
      },
      content3: {
        title: titleContent3,
        image: imageContent3,
        text: textContent3,
        textH: textHContent3,
        content: mainContent3,
      },
      content4: {
        title: titleContent4,
        image: imageContent4,
        text: textContent4,
        textH: textHContent4,
        content: mainContent4,
      },
      content5: {
        title: titleContent5,
        text: textContent5,
        textH: textHContent5,
        content: mainContent5,
      },
      content6: {
        image: imageContent6,
        text: textContent6,
        textH: textHContent6,
        content: mainContent6,
      },
    };
    const body = {
      name: 'CAUCHUYENTHUONGHIEU',
      content: JSON.stringify(bodyValues),
    };
    console.log(body, 'body');
    handleConfig
      .UpdateConfig(body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Cập nhật dữ liệu thành công',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  const handleAdd = () => {
    const newlist = [...listData];
    newlist.push({ id: uuidv4(), name: '', note: '', content: '' });
    setListData(newlist);
  };
  const handleDelete = (idToDelete: string) => {
    const updatedList = listData.filter((item) => item.id !== idToDelete);
    setListData(updatedList);
  };

  const handleEdit = (id: string, newValue: string, fieldName: string) => {
    setListData((prevList) => {
      const updatedList = prevList.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            [fieldName]: newValue,
          };
        }
        return item;
      });
      return updatedList;
    });
  };
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="px-5 py-5 mt-5 bg-white mb-[50px]">
        <div className="font-bold">
          1. Ảnh Baner và Text(không quá 3mb, tỉ lệ dài : cao - 1440 : 430 )
        </div>
        <div className="flex items-center justify-between">
          <UploadCustomV2
            setImageUrl={setImageUrl1}
            urlImage={imageUrl1}
            imageClass={ImageCalssEnum.Certificate}
            forlderName={forlderName}
          ></UploadCustomV2>
          <Input
            placeholder="Nội dung"
            value={content1}
            onChange={(e) => setContent1(e.target.value)}
          ></Input>
        </div>
        <div className="font-bold">2. Nội dung</div>
        <div className="my-3">
          <Input
            placeholder="Tiêu đề"
            value={titleContent2}
            onChange={(e) => setTitleContent2(e.target.value)}
          ></Input>
          <div className="my-4">
            <Ckeditor
              data={mainContent2}
              setData={setMainContent2}
              folder={'service'}
            />
          </div>
          <div className="flex items-center justify-between">
            <Input
              placeholder="Nội dung"
              value={textContent2}
              onChange={(e) => setTextContent2(e.target.value)}
            ></Input>
            <Input
              placeholder="Nội dung Hightlight"
              className="ml-5"
              value={textHContent2}
              onChange={(e) => setTextHContent2(e.target.value)}
            ></Input>
          </div>
        </div>
        <div className="mt-5 font-bold">3. Nội dung Trạm 1</div>
        <div className="flex items-center justify-between">
          <div className="flex-1">
            <div className="flex items-center justify-between mb-5">
              <Input
                placeholder="Nội dung"
                value={textContent3}
                onChange={(e) => setTextContent3(e.target.value)}
              ></Input>
              <Input
                placeholder="Nội dung"
                className="ml-5"
                value={textHContent3}
                onChange={(e) => setTextHContent3(e.target.value)}
              ></Input>
            </div>
            <Input
              placeholder="Nội dung"
              className="mb-3"
              value={titleContent3}
              onChange={(e) => setTitleContent3(e.target.value)}
            ></Input>
            <TextArea
              placeholder="Nội dung"
              value={mainContent3}
              onChange={(e) => setMainContent3(e.target.value)}
            ></TextArea>
          </div>
          <UploadCustomV2
            setImageUrl={setImageContent3}
            urlImage={imageContent3}
            imageClass={ImageCalssEnum.Certificate}
            forlderName={forlderName}
          ></UploadCustomV2>
        </div>
        <div className="font-bold">4. Nội dung Trạm 2</div>
        <div className="flex items-center justify-between">
          <UploadCustomV2
            setImageUrl={setImageContent4}
            urlImage={imageContent4}
            imageClass={ImageCalssEnum.Certificate}
            forlderName={forlderName}
          ></UploadCustomV2>
          <div className="flex-1">
            <div className="flex items-center justify-between mb-5">
              <Input
                placeholder="Nội dung"
                value={textContent4}
                onChange={(e) => setTextContent4(e.target.value)}
              ></Input>
              <Input
                placeholder="Nội dung"
                className="ml-5"
                value={textHContent4}
                onChange={(e) => setTextHContent4(e.target.value)}
              ></Input>
            </div>
            <Input
              placeholder="Nội dung"
              className="mb-3"
              value={titleContent4}
              onChange={(e) => setTitleContent4(e.target.value)}
            ></Input>
            <TextArea
              placeholder="Nội dung"
              value={mainContent4}
              onChange={(e) => setMainContent4(e.target.value)}
            ></TextArea>
          </div>
        </div>
        <div className="mb-5 font-bold">5. Nội dung Trạm 3</div>
        <div className="flex items-center justify-between">
          <div className="flex-1">
            <div className="flex items-center justify-between mb-5">
              <Input
                placeholder="Nội dung"
                value={textContent5}
                onChange={(e) => setTextContent5(e.target.value)}
              ></Input>
              <Input
                placeholder="Nội dung"
                className="ml-5"
                value={textHContent5}
                onChange={(e) => setTextHContent5(e.target.value)}
              ></Input>
            </div>
            <Input
              placeholder="Nội dung"
              className="mb-3"
              value={titleContent5}
              onChange={(e) => setTitleContent5(e.target.value)}
            ></Input>
            <TextArea
              placeholder="Nội dung"
              value={mainContent5}
              onChange={(e) => setMainContent5(e.target.value)}
            ></TextArea>
          </div>
        </div>
        <div className="mt-6 font-bold">6. Nội dung hành trình & ảnh nền</div>
        <div className="mt-5">
          <div className="flex-1">
            <div className="flex items-center justify-between mb-5">
              <Input
                placeholder="Nội dung"
                value={textContent6}
                onChange={(e) => setTextContent6(e.target.value)}
              ></Input>
              <Input
                placeholder="Nội dung"
                className="ml-5"
                value={textHContent6}
                onChange={(e) => setTextHContent6(e.target.value)}
              ></Input>
            </div>
            <TextArea
              placeholder="Nội dung"
              value={mainContent6}
              onChange={(e) => setMainContent6(e.target.value)}
            ></TextArea>
          </div>
          <UploadCustomV2
            setImageUrl={setImageContent6}
            urlImage={imageContent6}
            imageClass={ImageCalssEnum.Certificate}
            forlderName={forlderName}
          ></UploadCustomV2>
        </div>

        <div className="flex justify-center w-full">
          <Button type="primary" onClick={onFinish}>
            Cập nhật dữ liệu
          </Button>
        </div>
      </div>
    </>
  );
};
