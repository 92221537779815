import axios from 'axios';

export const instance = axios.create({
  baseURL: '',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const instanceClient = axios.create({
  baseURL: '',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const instanceExcel = axios.create({
  baseURL: '',
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export const instanceUploadS3 = axios.create({
  baseURL: '',
  headers: {
    'Content-Type': 'image/gif',
  },
});

export const axiosInstanceClient = axios.create();
axiosInstanceClient.interceptors.request.use((config) => {
  config.headers['Content-type'] = 'application/json';
  return config;
});
