import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, notification, Spin } from 'antd';
import { handleReport } from '@services';
import TabelComponent from '@components/Table';
import { breadcrumbListReport as breadcrumbItems } from '@components/Breadcrumb';
import { ColumnsListReport as columns } from '@components/Table/Columns';
import { getParamsFromUrl } from '@utils/Functions/getParams';
import { FilterConfig } from '@components/Filter';
import '../index.scss';

export const ListReport = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(20);
  const [open, setOpen] = useState(false);
  const [openExcel, setOpenExcel] = useState(false);
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState<any>();
  const [tableLoading, setTableLoading] = useState(false);

  const title = 'Danh sách khiếu nại';
  const titleButton = '';
  const routerAdd = '';
  const handleGetListCategory = async () => {
    const urlParams = getParamsFromUrl(location);
    setTableLoading(true);
    handleReport
      .GetListReport(urlParams)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        console.log(data, 'data');
        if (statusCode === 200) {
          setData(data);
          setCurrentPage(Number(optional?.pageIndex));
          setTotalItems(Number(optional?.getTotalElements));
          setTableLoading(false);
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
          setTableLoading(false);
        }
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error);
      });
  };
  useEffect(() => {
    if (
      location &&
      queryParams.get('pageIndex') &&
      queryParams.get('pageSize')
    ) {
      handleGetListCategory();
    }
  }, [location]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const params = new URLSearchParams(window.location.search);
    if (params.has('pageIndex')) {
      params.set('pageIndex', page);
    } else {
      params.append('pageIndex', page);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };

  const showModal = (data) => {
    setModalData(data);
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
    setModalData(null);
  };

  const handleEdit = (record: any) => {
    console.log(record, 'record');
    navigate(`/group-service/${record._id}`);
  };

  const [dataFilter, setDataFilter] = useState([]);
  const keyName = 'LISTGROUPSERVICECOLUMN';
  useEffect(() => {
    setDataFilter([
      {
        value: '1',
        label: 'Tên danh mục dịch vụ',
        type: 'input',
        name: 'name',
        placeholder: 'Nhập tên danh mục dịch vụ',
      },
      {
        value: '2',
        label: 'Trạng thái hoạt động',
        type: 'input',
        name: 'active',
        placeholder: 'Lựa chọn trạng thái hoạt động',
      },
      {
        value: '3',
        label: 'Bài đăng chi tiết',
        type: 'input',
        name: 'active',
        placeholder: 'Lựa chọn lọc dịch vụ với bài đăng',
      },
    ]);
  }, []);

  const customTotal = () => {
    return (
      <span className="pagination-table-title">
        Tổng {totalItems} khiếu nại
      </span>
    );
  };
  return (
    <Spin spinning={loading}>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <FilterConfig
        dataFilter={dataFilter}
        setDataFilter={setDataFilter}
        keyName={keyName}
        tableLoading={tableLoading}
      />

      <TabelComponent
        columns={columns()}
        data={data}
        tableLoading={tableLoading}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalItems={totalItems}
        customTotal={customTotal}
      />
    </Spin>
  );
};
