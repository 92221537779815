import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { notification, Input, Button, Switch } from 'antd';
import { handleConfig } from '@services';
import { useNavigate } from 'react-router-dom';
import { breadcrumbConfigFooter as breadcrumbItems } from '@components/Breadcrumb';
import UploadCustomV2 from '@components/UploadImage/UploadMono';
import { CategoryForm } from '@components/Form/Category';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';
import UploadMutipleCustom from '@components/UploadImage/UploadMutiple';
import { v4 as uuidv4 } from 'uuid';
import '../index.scss';
import TextArea from 'antd/es/input/TextArea';
import Ckeditor from '@components/Editor/CkeditorEnable';
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { oneDark } from '@codemirror/theme-one-dark';

export const ConfigFooter = () => {
  const navigate = useNavigate();

  const [code, setCode] = useState('// Write your code here\n');
  const [useCode, setUseCode] = useState(false);
  const [listData, setListData] = useState<any>([
    {
      id: uuidv4(),
      name: '',
      slug: '',
    },
  ]);
  const [listData2, setListData2] = useState<any>([
    {
      id: uuidv4(),
      name: '',
      slug: '',
    },
  ]);
  const [titleContent1, setTitleContent1] = useState('');
  const [titleContent2, setTitleContent2] = useState('');

  const [titleContent3a, setTitleContent3a] = useState('');
  const [titleContent3b, setTitleContent3b] = useState('');

  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const [facebook, setFacebook] = useState('');
  const [instargram, setInstargram] = useState('');
  const [zalo, setZalo] = useState('');

  const [footer, setFooter] = useState('');

  const title = 'Cấu hình giao diện chân trang Footer';
  const titleButton = '';
  const routerAdd = '';
  const forlderName = 'home';
  const handleGetDataDetail = async () => {
    handleConfig
      .GetDetailConfig('FOOTER')
      .then((response: any) => {
        const { statusCode, data } = response;
        if (data && data?.content) {
          const contenJson = JSON.parse(data?.content);
          console.log(contenJson, 'contenJson');
          if (contenJson) {
            setCode(contenJson?.code);
            setUseCode(contenJson?.active);

            setTitleContent1(contenJson?.content1?.title);
            if (contenJson?.content1?.content) {
              setListData(contenJson?.content1?.content);
            }

            setTitleContent2(contenJson?.content2?.title1);
            if (contenJson?.content2?.content1) {
              setListData2(contenJson?.content2?.content1);
            }

            setTitleContent3a(contenJson?.content2?.title2a);
            setTitleContent3b(contenJson?.content2?.title2b);

            setPhone(contenJson?.content3?.phone);
            setEmail(contenJson?.content3?.email);

            setFacebook(contenJson?.content3?.facebook);
            setInstargram(contenJson?.content3?.instargram);
            setZalo(contenJson?.content3?.zalo);

            setFooter(contenJson?.footer);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    handleGetDataDetail();
  }, []);
  const onFinish = () => {
    const bodyValues = {
      active: useCode,
      code: code,
      content1: {
        title: titleContent1,
        content: listData,
      },
      content2: {
        title1: titleContent2,
        content1: listData2,
        title2a: titleContent3a,
        title2b: titleContent3b,
      },
      content3: {
        phone: phone,
        email: email,
        facebook: facebook,
        instargram: instargram,
        zalo: zalo,
      },
      footer: footer,
    };
    const body = {
      name: 'FOOTER',
      content: JSON.stringify(bodyValues),
    };
    console.log(body, 'body');
    handleConfig
      .UpdateConfig(body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Cập nhật dữ liệu thành công',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleAdd = () => {
    const newlist = [...listData];
    newlist.push({ id: uuidv4(), name: '', slug: '' });
    setListData(newlist);
  };
  const handleAdd2 = () => {
    const newlist = [...listData2];
    newlist.push({ id: uuidv4(), name: '', slug: '' });
    setListData2(newlist);
  };
  const handleDelete = (idToDelete: string) => {
    const updatedList = listData.filter((item) => item.id !== idToDelete);
    setListData(updatedList);
  };
  const handleDelete2 = (idToDelete: string) => {
    const updatedList = listData2.filter((item) => item.id !== idToDelete);
    setListData2(updatedList);
  };
  const handleEdit = (id: string, newValue: string, fieldName: string) => {
    setListData((prevList) => {
      const updatedList = prevList.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            [fieldName]: newValue,
          };
        }
        return item;
      });
      return updatedList;
    });
  };
  const handleEdit2 = (id: string, newValue: string, fieldName: string) => {
    setListData2((prevList) => {
      const updatedList = prevList.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            [fieldName]: newValue,
          };
        }
        return item;
      });
      return updatedList;
    });
  };
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="px-5 py-5 mt-5 bg-white mb-[50px]">
        <div className="flex items-center mb-5">
          <Switch value={useCode} onChange={() => setUseCode(!useCode)} />
          <span className="ml-3">
            {useCode
              ? 'Sử dụng mã html code cho chân trang'
              : 'Sửa dụng giao diện Footer mặc định'}
          </span>
        </div>

        {useCode ? (
          <>
            <div className="mb-2 font-bold">Nhập mã code</div>
            <CodeMirror
              value={code}
              height="500px"
              extensions={[javascript()]}
              theme={oneDark}
              onChange={(value) => setCode(value)}
            />
          </>
        ) : (
          <>
            <div className="">
              <div className="mb-5 font-bold">1. Nội dung cột 1</div>
              <Input
                placeholder="Nhập tiêu đề"
                className="mb-4"
                value={titleContent1}
                onChange={(e) => setTitleContent1(e.target.value)}
              />
              {listData &&
                listData.map((item, index) => (
                  <div
                    key={item.id}
                    className="flex mx-10 border-b-[1px] mb-5 pb-5"
                  >
                    <Input
                      placeholder="Nhập tiêu đề"
                      className=""
                      value={item.name}
                      onChange={(e) =>
                        handleEdit(item.id, e.target.value, 'name')
                      }
                    />
                    <Input
                      placeholder="Nhập đường dẫn (bắt buộc)"
                      className="mx-5"
                      value={item.slug}
                      onChange={(e) =>
                        handleEdit(item.id, e.target.value, 'slug')
                      }
                    />

                    <Button
                      onClick={() => handleDelete(item.id)}
                      className="ml-1"
                    >
                      Xoá
                    </Button>
                  </div>
                ))}
              <div className="flex justify-end mx-10 mt-5 mb-5">
                <Button onClick={handleAdd} type="dashed">
                  Thêm
                </Button>
              </div>

              <div className="mb-5 font-bold">2. Nội dung cột 2</div>
              <Input
                placeholder="Nhập tiêu đề"
                className="mb-4"
                value={titleContent2}
                onChange={(e) => setTitleContent2(e.target.value)}
              />
              {listData2 &&
                listData2.map((item, index) => (
                  <div
                    key={item.id}
                    className="flex mx-10 border-b-[1px] mb-5 pb-5"
                  >
                    <Input
                      placeholder="Nhập tiêu đề"
                      className=""
                      value={item.name}
                      onChange={(e) =>
                        handleEdit2(item.id, e.target.value, 'name')
                      }
                    />
                    <Input
                      placeholder="Nhập đường dẫn (bắt buộc)"
                      className="mx-5"
                      value={item.slug}
                      onChange={(e) =>
                        handleEdit2(item.id, e.target.value, 'slug')
                      }
                    />

                    <Button
                      onClick={() => handleDelete2(item.id)}
                      className="ml-1"
                    >
                      Xoá
                    </Button>
                  </div>
                ))}
              <div className="flex justify-end mx-10 mt-5 mb-5">
                <Button onClick={handleAdd2} type="dashed">
                  Thêm
                </Button>
              </div>
              <div className="mb-5 font-bold">
                3. Thông tin thời gian hoạt động
              </div>
              <div className="flex mb-5">
                <Input
                  placeholder="Nhập thông tin"
                  className=""
                  value={titleContent3a}
                  onChange={(e) => setTitleContent3a(e.target.value)}
                />
                <Input
                  placeholder="Nhập thông tin"
                  className="ml-5"
                  value={titleContent3b}
                  onChange={(e) => setTitleContent3b(e.target.value)}
                />
              </div>
              <div className="mb-5 font-bold">4. Thông tin liên hệ</div>
              <div className="flex mb-5">
                <Input
                  placeholder="Nhập số hotline"
                  className=""
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <Input
                  placeholder="Nhập email"
                  className="ml-5"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-5 font-bold">5. Mạng xã hội</div>

              <Input
                placeholder="Nhập link facebook"
                className="mb-4 w-[60%]"
                value={facebook}
                onChange={(e) => setFacebook(e.target.value)}
              />
              <Input
                placeholder="Nhập link instargram"
                className="mb-4 w-[60%]"
                value={instargram}
                onChange={(e) => setInstargram(e.target.value)}
              />
              <Input
                placeholder="Nhập link Zalo"
                className="w-[60%]"
                value={zalo}
                onChange={(e) => setZalo(e.target.value)}
              />
              <div className="my-5 font-bold">6. Thông tin chân trang</div>

              <TextArea
                placeholder="Nhập thông tin"
                className=""
                value={footer}
                onChange={(e) => setFooter(e.target.value)}
              />
            </div>
          </>
        )}

        <div className="flex justify-center w-full mt-10">
          <Button type="primary" onClick={onFinish}>
            Cập nhật dữ liệu
          </Button>
        </div>
      </div>
    </>
  );
};
