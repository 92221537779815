import React, { useState, useEffect } from 'react';
import { Button, Input, Form, Switch } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { CategoryType } from './type';
import { removeVietnamese } from '@utils/Functions/RemoveVietnamese';
import TextArea from 'antd/es/input/TextArea';

export const GroupServicesV2Form = ({
  initialValues,
  onFinish,
  onFinishFailed,
}) => {
  return (
    <Form
      key={JSON.stringify(initialValues)}
      initialValues={initialValues}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      className="mb-0 main-add-form"
    >
      <Form.Item
        label="Tiêu đề"
        name="name"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập tiêu đề nhóm dịch vụ',
          },
        ]}
        className="mb-0"
      >
        <Input placeholder="Nhập tiêu đề nhóm dịch vụ" />
      </Form.Item>
      <Form.Item label="Content/ Hightlight" className="mb-0">
        <div className="flex justify-between mg-bttom-0">
          <Form.Item name="text" className="w-[48%] mg-bttom-0">
            <Input
              placeholder="Nhập content"
              className="text-[#274239] font-bricolage font-[400]"
            />
          </Form.Item>
          <Form.Item name="hightlight" className="w-[48%] mg-bttom-0">
            <Input
              placeholder="Nhập hightlight"
              className="text-[#769A6E] font-bricolage font-[400]"
            />
          </Form.Item>
        </div>
      </Form.Item>
      <Form.Item
        label="Hiển thị trang chủ"
        name="show_home"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn trạng thái hiển thị trên trang chủ',
          },
        ]}
      >
        <Switch
          checkedChildren="Hiển thị"
          unCheckedChildren="Không hiển thị"
          defaultChecked
        />
      </Form.Item>
      <Form.Item
        label="Hiển thị dạng minium"
        name="minium"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn kiểu hiển thị',
          },
        ]}
      >
        <Switch checkedChildren="Mở" unCheckedChildren="Đóng" defaultChecked />
      </Form.Item>
      <Form.Item
        label="Trạng thái"
        name="active"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn trạng thái hoạt động',
          },
        ]}
      >
        <Switch
          checkedChildren="Hiển thị"
          unCheckedChildren="Tạm ẩn"
          defaultChecked
        />
      </Form.Item>
      <Form.Item label="Mô tả" name="description">
        <TextArea placeholder="Nhập mô tả cho nhóm dịch vụ" />
      </Form.Item>
      <div className="flex justify-end mb-10">
        <Button type="primary" className="button-submit" htmlType="submit">
          {!initialValues?.config ? 'Tạo mới ' : 'Cập nhật'}
        </Button>
      </div>
    </Form>
  );
};
