import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { notification, Input, Button } from 'antd';
import { handleConfig } from '@services';
import { useNavigate } from 'react-router-dom';
import { breadcrumbConfigCauHoi as breadcrumbItems } from '@components/Breadcrumb';
import UploadCustomV2 from '@components/UploadImage/UploadMono';
import { CategoryForm } from '@components/Form/Category';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';
import UploadMutipleCustom from '@components/UploadImage/UploadMutiple';
import { v4 as uuidv4 } from 'uuid';
import '../index.scss';
import TextArea from 'antd/es/input/TextArea';
import Ckeditor from '@components/Editor/CkeditorEnable';

export const ConfigCauHoi = () => {
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState('');
  const [titleP, setTitleP] = useState<string>('');
  const [content, setContent] = useState<string>('');

  const title = 'Cấu hình giao diện trang câu hỏi thường gặp';
  const titleButton = '';
  const routerAdd = '';
  const forlderName = 'home';
  const handleGetDataDetail = async () => {
    handleConfig
      .GetDetailConfig('CAUHOI')
      .then((response: any) => {
        const { statusCode, data } = response;
        if (data && data?.content) {
          const contenJson = JSON.parse(data?.content);
          console.log(contenJson, 'contenJson');
          if (contenJson) {
            setImageUrl(contenJson?.image);
            setTitleP(contenJson?.title);
            setContent(contenJson?.content);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    handleGetDataDetail();
  }, []);
  const onFinish = () => {
    const bodyValues = {
      image: imageUrl,
      title: titleP,
      content: content,
    };
    const body = {
      name: 'CAUHOI',
      content: JSON.stringify(bodyValues),
    };
    console.log(body, 'body');
    handleConfig
      .UpdateConfig(body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Cập nhật dữ liệu thành công',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="px-5 py-5 mt-5 bg-white mb-[50px]">
        <div className="font-bold">
          1. Ảnh Baner (không quá 3mb, tỉ lệ dài : cao - 1440 : 430 )
        </div>
        <UploadCustomV2
          setImageUrl={setImageUrl}
          urlImage={imageUrl}
          imageClass={ImageCalssEnum.Certificate}
          forlderName={forlderName}
        ></UploadCustomV2>
        <div className="font-bold">2. Tiêu đề</div>
        <div className="flex items-center justify-between">
          <Input
            placeholder="Nội dung"
            className="my-5"
            value={titleP}
            onChange={(e) => setTitleP(e.target.value)}
          ></Input>
        </div>
        <div className="font-bold">3. Nội dung</div>
        <div className="my-4">
          <Ckeditor data={content} setData={setContent} folder={'service'} />
        </div>
        <div className="flex justify-center w-full">
          <Button type="primary" onClick={onFinish}>
            Cập nhật dữ liệu
          </Button>
        </div>
      </div>
    </>
  );
};
