export const removeVietnamese = (str: string) => {
  // Kiểm tra nếu chuỗi là null hoặc undefined
  if (
    str == null ||
    str === undefined ||
    str === 'null' ||
    str === 'undefined'
  ) {
    return '';
  }
  // Chuyển đổi chữ hoa thành chữ thường
  let newString = str.toLowerCase();
  // Loại bỏ dấu tiếng Việt
  newString = newString
    .replace(/[àáạảãâầấậẩẫăằắặẳẵ]/g, 'a')
    .replace(/[èéẹẻẽêềếệểễ]/g, 'e')
    .replace(/[ìíịỉĩ]/g, 'i')
    .replace(/[òóọỏõôồốộổỗơờớợởỡ]/g, 'o')
    .replace(/[ùúụủũưừứựửữ]/g, 'u')
    .replace(/[ỳýỵỷỹ]/g, 'y')
    .replace(/đ/g, 'd');

  return newString;
};

// Hàm chuyển đổi chuỗi thành slug
export const convertToSlug = (str) => {
  // Bỏ dấu tiếng Việt
  str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  // Chuyển thành chữ thường
  str = str.toLowerCase();

  // Thay thế khoảng trắng bằng dấu -
  str = str.replace(/\s+/g, '-');

  // Loại bỏ các ký tự không phải chữ cái, số, hoặc dấu -
  str = str.replace(/[^a-z0-9-]/g, '');

  return str;
};
