import { instance } from '@configs/instance';

const updateConfig = (body: any): Promise<any> => {
  return instance.put(
    process.env.REACT_APP_API_MARKETING + `/config/update`,
    body,
  );
};

const getDetailConfig = (name: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_MARKETING + `/config/detail/${name}`,
  );
};

export default {
  updateConfig,
  getDetailConfig,
};
