import ReportEndpoint from '@api/report';

export const GetListReport = async (param: any): Promise<any | Error> => {
  try {
    const response = await ReportEndpoint.getListReport(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};
