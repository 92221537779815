import ConfigEndpoint from '@api/config';

export const UpdateConfig = async (body: any): Promise<any | Error> => {
  try {
    const response = await ConfigEndpoint.updateConfig(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export const GetDetailConfig = async (name: any): Promise<any | Error> => {
  try {
    const response = await ConfigEndpoint.getDetailConfig(name);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};
