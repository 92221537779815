import AuthEndpoint from '@api/auth';

export const GetCurrent = async (): Promise<any | Error> => {
  try {
    const response = await AuthEndpoint.getCurrent();
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};
