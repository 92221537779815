// MainRouter.tsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Page404 from '@pages/Errors/404';
import Page403 from '@pages/Errors/403';
import Page500 from '@pages/Errors/500';
import HomePage from '@pages/Home';

import DashboardLayout from '@layouts/DashboardLayout';

import { ListServices } from '@pages/Service/ListService/List';
import { DetailService } from '@pages/Service/ListService/Detail';

import { ListGroupServices } from '@pages/Service/GroupService/List';
import { AddGroupServices } from '@pages/Service/GroupService/Add';
import { DetailGroupServices } from '@pages/Service/GroupService/Detail';

import { ListReport } from '@pages/Report/List';

// các phần mới
import { ListServicesV2 } from '@pages/ServiceV2/ListService/List';
import { DetailServiceV2 } from '@pages/ServiceV2/ListService/Detail';

import { ListGroupServicesV2 } from '@pages/ServiceV2/GroupService/List';
import { AddGroupServicesV2 } from '@pages/ServiceV2/GroupService/Add';
import { DetailGroupServicesV2 } from '@pages/ServiceV2/GroupService/Detail';

import { AddGroupNews } from '@pages/News/GroupNews/Add';
import { DetailGroupNews } from '@pages/News/GroupNews/Detail';
import { ListGroupNews } from '@pages/News/GroupNews/List';

import { AddNews } from '@pages/News/ListNews/Add';
import { DetailNews } from '@pages/News/ListNews/Detail';
import { ListNews } from '@pages/News/ListNews/List';

import { ConfigHome } from '@pages/Config/Home';
import { ConfigCauChuyenThuongHieu } from '@pages/Config/CauChuyenThuongHieu';
import { ConfigCauHoi } from '@pages/Config/CauHoi';
import { ConfigChinhSach } from '@pages/Config/ChinhSach';
import { ConfigCuaHang } from '@pages/Config/CuaHang';
import { ConfigDanhMucDichVu } from '@pages/Config/DanhMucDichVu';
import { ConfigDatLich } from '@pages/Config/DatLich';
import { ConfigDichVu } from '@pages/Config/DichVu';
import { ConfigFooter } from '@pages/Config/Footer';
import { ConfigGiaTriCotLoi } from '@pages/Config/GiaTriCotLoi';
import { ConfigLogin } from '@pages/Config/Login';
import { ConfigTaiKhoan } from '@pages/Config/TaiKhoan';
const MainRouter = () => {
  return (
    <div>
      <Routes>
        {/* Sử dụng DashboardLayout cho các routes của MainRouter */}
        <Route
          path="/"
          element={
            <DashboardLayout>
              <HomePage />
            </DashboardLayout>
          }
        />
        <Route
          path="/service/list"
          element={
            <DashboardLayout>
              <ListServices />
            </DashboardLayout>
          }
        />
        <Route
          path="/service/:id"
          element={
            <DashboardLayout>
              <DetailService />
            </DashboardLayout>
          }
        />
        <Route
          path="/group-service/list"
          element={
            <DashboardLayout>
              <ListGroupServices />
            </DashboardLayout>
          }
        />
        <Route
          path="/group-service/add"
          element={
            <DashboardLayout>
              <AddGroupServices />
            </DashboardLayout>
          }
        />
        <Route
          path="/group-service/:id"
          element={
            <DashboardLayout>
              <DetailGroupServices />
            </DashboardLayout>
          }
        />

        <Route
          path="/v2-service/list"
          element={
            <DashboardLayout>
              <ListServicesV2 />
            </DashboardLayout>
          }
        />
        <Route
          path="/v2-service/:id"
          element={
            <DashboardLayout>
              <DetailServiceV2 />
            </DashboardLayout>
          }
        />
        <Route
          path="/v2-group-service/list"
          element={
            <DashboardLayout>
              <ListGroupServicesV2 />
            </DashboardLayout>
          }
        />
        <Route
          path="/v2-group-service/add"
          element={
            <DashboardLayout>
              <AddGroupServicesV2 />
            </DashboardLayout>
          }
        />
        <Route
          path="/v2-group-service/:id"
          element={
            <DashboardLayout>
              <DetailGroupServicesV2 />
            </DashboardLayout>
          }
        />

        <Route
          path="/report/list"
          element={
            <DashboardLayout>
              <ListReport />
            </DashboardLayout>
          }
        />
        <Route
          path="/group-news/list"
          element={
            <DashboardLayout>
              <ListGroupNews />
            </DashboardLayout>
          }
        />
        <Route
          path="/group-news/add"
          element={
            <DashboardLayout>
              <AddGroupNews />
            </DashboardLayout>
          }
        />
        <Route
          path="/group-news/:id"
          element={
            <DashboardLayout>
              <DetailGroupNews />
            </DashboardLayout>
          }
        />
        <Route
          path="/news/list"
          element={
            <DashboardLayout>
              <ListNews />
            </DashboardLayout>
          }
        />
        <Route
          path="/news/add"
          element={
            <DashboardLayout>
              <AddNews />
            </DashboardLayout>
          }
        />
        <Route
          path="/news/:id"
          element={
            <DashboardLayout>
              <DetailNews />
            </DashboardLayout>
          }
        />
        <Route
          path="/config/home"
          element={
            <DashboardLayout>
              <ConfigHome />
            </DashboardLayout>
          }
        />
        <Route
          path="/config/cau-chuyen-thuong-hieu"
          element={
            <DashboardLayout>
              <ConfigCauChuyenThuongHieu />
            </DashboardLayout>
          }
        />
        <Route
          path="/config/cau-hoi"
          element={
            <DashboardLayout>
              <ConfigCauHoi />
            </DashboardLayout>
          }
        />
        <Route
          path="/config/chinh-sach"
          element={
            <DashboardLayout>
              <ConfigChinhSach />
            </DashboardLayout>
          }
        />
        <Route
          path="/config/cua-hang"
          element={
            <DashboardLayout>
              <ConfigCuaHang />
            </DashboardLayout>
          }
        />
        <Route
          path="/config/danh-muc-dich-vu"
          element={
            <DashboardLayout>
              <ConfigDanhMucDichVu />
            </DashboardLayout>
          }
        />
        <Route
          path="/config/dat-lich"
          element={
            <DashboardLayout>
              <ConfigDatLich />
            </DashboardLayout>
          }
        />
        <Route
          path="/config/dich-vu"
          element={
            <DashboardLayout>
              <ConfigDichVu />
            </DashboardLayout>
          }
        />
        <Route
          path="/config/footer"
          element={
            <DashboardLayout>
              <ConfigFooter />
            </DashboardLayout>
          }
        />
        <Route
          path="/config/gia-tri-cot-loi"
          element={
            <DashboardLayout>
              <ConfigGiaTriCotLoi />
            </DashboardLayout>
          }
        />
        <Route
          path="/config/login"
          element={
            <DashboardLayout>
              <ConfigLogin />
            </DashboardLayout>
          }
        />
        <Route
          path="/config/tai-khoan"
          element={
            <DashboardLayout>
              <ConfigTaiKhoan />
            </DashboardLayout>
          }
        />
        <Route
          path="/403"
          element={
            <DashboardLayout>
              <Page403 />
            </DashboardLayout>
          }
        />

        <Route
          path="/500"
          element={
            <DashboardLayout>
              <Page500 />
            </DashboardLayout>
          }
        />

        <Route
          path="/*"
          element={
            <DashboardLayout>
              <Page404 />
            </DashboardLayout>
          }
        />
      </Routes>
    </div>
  );
};

export default MainRouter;
