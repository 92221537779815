// DashboardLayout.tsx
import React, { useState, ReactNode, useEffect } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ShopOutlined,
  DownOutlined,
  LoginOutlined,
  UserOutlined,
  DollarOutlined,
  LineChartOutlined,
  ContainerOutlined,
  CalendarOutlined,
  ReconciliationOutlined,
  FileAddOutlined,
  ShoppingOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Menu, Dropdown, notification } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import './DashboardLayout.scss';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '@store';
import { setInfor, clearInfor } from '@store/auth/userSlice';
import { setToken } from '@store/auth/authSlice';
import { useDispatch } from 'react-redux';
import { handleAuth } from '@services';

type MenuItem = Required<MenuProps>['items'][number];

type MenuItems = {
  key: React.Key;
  icon?: React.ReactNode;
  children?: MenuItem[];
  label: React.ReactNode;
  type?: 'group';
  to?: string;
};

interface DashboardLayoutProps {
  children: React.ReactNode;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { UserInfor, Role } = useSelector((state: any) => state.userName);
  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState(['']);
  const [selectedKeys, setSelectedKeys] = useState(['1']);
  const [data, setData] = useState<any>();
  const navigate = useNavigate();
  const isLoading = useSelector(
    (state: { loading: { loading: boolean } }) => state.loading.loading,
  );
  const handleResize = () => {
    if (window.innerWidth < 1200) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  };
  const handleCheckuser = async () => {
    handleAuth
      .GetCurrent()
      .then((response: any) => {
        const { data, statusCode } = response;
        if (statusCode === 200) {
          setData(data);
          dispatch(setInfor(data));
        } else if (statusCode === 403) {
          Cookies.remove('refreshToken');
          Cookies.remove('accessToken');
          dispatch(clearInfor());
          dispatch(setToken(null));
          const currentUrl = window.location.href;
          window.location.href =
            process.env.REACT_APP_SSO_DOMAIN + '?redirect=' + currentUrl;
        } else {
          notification.error({
            message: 'Lỗi xác thực',
            description: 'Lỗi khi kiểm tra thông tin tài khoản',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    handleCheckuser();
    handleResize(); // Gọi hàm handleResize khi component được render
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    if (location && location.pathname) {
      const url = location.pathname;

      if (url.includes('/service/')) {
        setOpenKeys(['sub1']);
        setSelectedKeys(['10']);
      } else if (url.includes('/group-service/')) {
        setOpenKeys(['sub1']);
        setSelectedKeys(['11']);
      }
      if (url.includes('/report/')) {
        setOpenKeys(['sub2']);
        setSelectedKeys(['20']);
      }
      if (url.includes('/news/')) {
        setOpenKeys(['sub3']);
        setSelectedKeys(['30']);
      }
      if (url.includes('/group-news/')) {
        setOpenKeys(['sub3']);
        setSelectedKeys(['31']);
      }
      if (url.includes('/config/')) {
        setOpenKeys(['sub4']);
        if (url.includes('/config/home')) {
          setSelectedKeys(['40']);
        }
        if (url.includes('/config/cau-chuyen-thuong-hieu')) {
          setSelectedKeys(['41']);
        }
        if (url.includes('/config/cau-hoi')) {
          setSelectedKeys(['42']);
        }
        if (url.includes('/config/chinh-sach')) {
          setSelectedKeys(['43']);
        }
        if (url.includes('/config/cua-hang')) {
          setSelectedKeys(['44']);
        }
        if (url.includes('/config/danh-muc-dich-vu')) {
          setSelectedKeys(['45']);
        }
        if (url.includes('/config/dich-vu')) {
          setSelectedKeys(['46']);
        }
        if (url.includes('/config/dat-lich')) {
          setSelectedKeys(['47']);
        }
        if (url.includes('/config/gia-tri-cot-loi')) {
          setSelectedKeys(['48']);
        }
        if (url.includes('/config/login')) {
          setSelectedKeys(['49']);
        }
        if (url.includes('/config/tai-khoan')) {
          setSelectedKeys(['50']);
        }
        if (url.includes('/config/footer')) {
          setSelectedKeys(['51']);
        }
      }
      if (url.includes('/v2-service/')) {
        setOpenKeys(['sub5']);
        setSelectedKeys(['50']);
      }
      if (url.includes('/v2-group-service/')) {
        setOpenKeys(['sub5']);
        setSelectedKeys(['51']);
      }
    }
  }, [location.pathname]);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  function getItem(
    label: string,
    key: string,
    icon?: ReactNode,
    children?: MenuItem[],
    to?: string,
  ) {
    return {
      key,
      label,
      icon,
      children,
      to,
    };
  }
  // Render menu item function
  function renderMenuItem(item: MenuItems) {
    if (item.children) {
      return (
        <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
          {item.children.map(renderMenuItem)}
        </Menu.SubMenu>
      );
    } else if (item.to) {
      return (
        <Menu.Item key={item.key} icon={item.icon}>
          <Link to={item.to}>{item.label}</Link>
        </Menu.Item>
      );
    } else {
      return (
        <Menu.Item key={item.key} icon={item.icon}>
          {item.label}
        </Menu.Item>
      );
    }
  }
  const [items, setItems] = useState([]);
  useEffect(() => {
    setItems([
      // Add an image to the menu as the first item
      {
        key: 'logo',
        label: (
          <Link to={'/'}>
            {collapsed ? (
              <img src={'/fav.png'} alt="Logo" className={'logo-collapsed'} />
            ) : (
              <img
                src={'/logo_white.png'}
                alt="Logo"
                className={'logo-dis-collapsed'}
              />
            )}
          </Link>
        ),
        type: 'group',
      },
      getItem('Dịch vụ', 'sub1', <ShoppingOutlined />, [
        getItem(
          'Danh sách dịch vụ',
          '10',
          undefined,
          undefined,
          '/service/list',
        ),
        getItem(
          'Nhóm dịch vụ',
          '11',
          undefined,
          undefined,
          '/group-service/list',
        ),
      ]),
      getItem('Dịch vụ (mới)', 'sub5', <ShoppingOutlined />, [
        getItem(
          'Danh sách dịch vụ (mới)',
          '50',
          undefined,
          undefined,
          '/v2-service/list',
        ),
        getItem(
          'Nhóm dịch vụ (mới)',
          '51',
          undefined,
          undefined,
          '/v2-group-service/list',
        ),
      ]),
      getItem('Bài viết tin tức', 'sub3', <FileAddOutlined />, [
        getItem('Tin tức', '30', undefined, undefined, '/news/list'),
        getItem('Nhóm tin tức', '31', undefined, undefined, '/group-news/list'),
      ]),
      getItem('Khiếu nại', 'sub2', <WarningOutlined />, [
        getItem(
          'Danh sách khiếu nại',
          '20',
          undefined,
          undefined,
          '/report/list',
        ),
      ]),
      getItem('Cấu hình layout', 'sub4', <ReconciliationOutlined />, [
        getItem('Trang chủ', '40', undefined, undefined, '/config/home'),

        getItem(
          'Câu chuyện thương hiệu',
          '41',
          undefined,
          undefined,
          '/config/cau-chuyen-thuong-hieu',
        ),
        getItem(
          'Câu hỏi thường gặp',
          '42',
          undefined,
          undefined,
          '/config/cau-hoi',
        ),
        getItem('Chính sách', '43', undefined, undefined, '/config/chinh-sach'),
        getItem('Cửa hàng', '44', undefined, undefined, '/config/cua-hang'),
        getItem(
          'Danh mục dịch vụ',
          '45',
          undefined,
          undefined,
          '/config/danh-muc-dich-vu',
        ),
        getItem('Dịch vụ', '46', undefined, undefined, '/config/dich-vu'),
        getItem('Đặt lịch', '47', undefined, undefined, '/config/dat-lich'),
        getItem(
          'Giá trị cốt lõi',
          '48',
          undefined,
          undefined,
          '/config/gia-tri-cot-loi',
        ),
        getItem(
          'Trang Đăngn nhập',
          '49',
          undefined,
          undefined,
          '/config/login',
        ),
        getItem(
          'Trang Tài khoản',
          '50',
          undefined,
          undefined,
          '/config/tai-khoan',
        ),
        getItem(
          'Chân Trang - Footer',
          '51',
          undefined,
          undefined,
          '/config/footer',
        ),
      ]),
    ]);
  }, [collapsed]);

  const handleMenuOpenChange = (keys: any) => {
    setOpenKeys(keys.length > 0 ? keys : '');
  };
  const handleLogout = () => {
    // tiến hành xóa dữ liệu
    Cookies.remove('refreshToken');
    Cookies.remove('accessToken');
    dispatch(clearInfor());
    dispatch(setToken(null));
    const currentUrl = window.location.href;
    window.location.href =
      process.env.REACT_APP_SSO_DOMAIN + '?redirect=' + currentUrl;
    // navigate('/login');
  };
  const menu = (
    <Menu>
      <Menu.Item
        key={'1'}
        icon={<LoginOutlined />}
        danger={true}
        disabled={false}
        onClick={handleLogout}
      >
        Đăng xuất
      </Menu.Item>
    </Menu>
  );

  return (
    <Spin tip="Tải dữ liệu..." spinning={false}>
      <div className="main" style={{ display: 'flex' }}>
        <div className="main-sidebar">
          {items && (
            <Menu
              // defaultSelectedKeys={['sub1']}
              // defaultOpenKeys={['sub2']}
              mode="inline"
              theme="dark"
              selectedKeys={selectedKeys}
              openKeys={openKeys}
              onOpenChange={handleMenuOpenChange}
              inlineCollapsed={collapsed}
              style={{ height: '100vh' }}
            >
              {items.map(renderMenuItem)}
            </Menu>
          )}
        </div>
        <div className={!collapsed ? 'main-wrap' : 'main-wrap-collapsed'}>
          <div className="navbar">
            {collapsed ? (
              <MenuUnfoldOutlined
                onClick={toggleCollapsed}
                className="navbar-icon"
              />
            ) : (
              <MenuFoldOutlined
                onClick={toggleCollapsed}
                className="navbar-icon"
              />
            )}
            {data && (
              <Dropdown overlay={menu} trigger={['click']}>
                <Button type="text">
                  Xin chào
                  <span style={{ marginLeft: 10, fontWeight: 600 }}>
                    {data.name ? data.name : data.username}
                  </span>
                  <DownOutlined />
                </Button>
              </Dropdown>
            )}
          </div>
          <div className="main-spin">
            <Spin
              tip="Đang lấy dữ liệu ..."
              spinning={isLoading}
              size="large"
              className="main-content"
            >
              {children}
            </Spin>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default DashboardLayout;
