import ServiceEndpoint from '@api/service';

export const GetListService = async (param: any): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.getListServices(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export const GetListServiceSearch = async (
  param: any,
): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.getListServicesSearch(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export const GetListGroupService = async (param: any): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.getListGroupServices(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export const GetDetailService = async (id: string): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.getDetailService(id);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export const UpdateService = async (
  serviceId: string,
  body: any,
): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.updateService(serviceId, body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export const UpdateBookingService = async (
  serviceId: string,
  body: any,
): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.updateBookingService(
      serviceId,
      body,
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export const UpdateStatusService = async (
  serviceId: string,
  body: any,
): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.updateStatusService(serviceId, body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export const CreateGroupService = async (body: any): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.createGroupService(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export const GetDetailGroupService = async (
  id: string,
): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.getDetailGroupService(id);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export const UpdateGroupService = async (
  id: string,
  body: any,
): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.updateGroupService(id, body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export const UpdateGroupServiceOnTop = async (
  id: string,
): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.updateGroupServiceOnTop(id);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

// Bản dịch vụ mới
// Nhóm dịch vụ V2

export const GetListGroupServicesV2 = async (
  param: any,
): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.getListGroupServicesV2(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export const CreateGroupV2 = async (param: any): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.createGroupV2(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};
export const UpdateGroupV2 = async (
  body: any,
  id: any,
): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.updateGroupV2(body, id);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};
export const DeleteGroupV2 = async (param: any): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.deleteGroupV2(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};
export const OnTopGroupV2 = async (param: any): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.onTopGroupV2(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};
export const DetailGroupV2 = async (param: any): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.detailGroupV2(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};
export const DroplistGroupV2 = async (): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.droplistGroupV2();
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

// Phần dịch vụ mới

export const UpdateServiceV2 = async (
  body: any,
  id: any,
): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.updateServiceV2(body, id);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};
export const DeleteServiceV2 = async (param: any): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.deleteServiceV2(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};
export const OnTopServiceV2 = async (param: any): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.onTopServiceV2(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};
export const DetailServiceV2 = async (param: any): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.detailServiceV2(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};
export const GetListServicesV2 = async (param: any): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.getListServicesV2(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};
