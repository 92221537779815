import NewsEndpoint from '@api/news';

export const GetListGroupNews = async (param: any): Promise<any | Error> => {
  try {
    const response = await NewsEndpoint.getListGroupNews(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export const CreateGroupNews = async (param: any): Promise<any | Error> => {
  try {
    const response = await NewsEndpoint.createGroupNews(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};
export const UpdateGroupNews = async (
  body: any,
  id: any,
): Promise<any | Error> => {
  try {
    const response = await NewsEndpoint.updateGroupNews(body, id);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};
export const DeleteGroupNews = async (param: any): Promise<any | Error> => {
  try {
    const response = await NewsEndpoint.deleteGroupNews(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};
export const OnTopGroupNews = async (param: any): Promise<any | Error> => {
  try {
    const response = await NewsEndpoint.onTopGroupNews(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};
export const DetailGroupNews = async (param: any): Promise<any | Error> => {
  try {
    const response = await NewsEndpoint.detailGroupNews(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};
export const DroplistGroupNews = async (): Promise<any | Error> => {
  try {
    const response = await NewsEndpoint.droplistGroupNews();
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

// Tin tức

export const GetListNews = async (param: any): Promise<any | Error> => {
  try {
    const response = await NewsEndpoint.getListNews(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export const CreateNews = async (body: any): Promise<any | Error> => {
  try {
    const response = await NewsEndpoint.createNews(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export const OnTopNews = async (body: any): Promise<any | Error> => {
  try {
    const response = await NewsEndpoint.onTopNews(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};
export const DetailNews = async (body: any): Promise<any | Error> => {
  try {
    const response = await NewsEndpoint.detailNews(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};
export const DeleteNews = async (body: any): Promise<any | Error> => {
  try {
    const response = await NewsEndpoint.deleteNews(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export const UpdateNews = async (body: any, id: any): Promise<any | Error> => {
  try {
    const response = await NewsEndpoint.updateNews(body, id);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};
