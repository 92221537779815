import { Link } from 'react-router-dom';

export const breadcrumbListService = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách dịch vụ' },
];

export const breadcrumbDetailService = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/service/list'}>Danh sách dịch vụ</Link> },
  { title: 'Chỉnh sửa dịch vụ' },
];
export const breadcrumbDetailServiceV2 = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/v2-service/list'}>Danh sách dịch vụ</Link> },
  { title: 'Chỉnh sửa dịch vụ' },
];

export const breadcrumbListGroupService = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách nhóm dịch vụ' },
];

export const breadcrumbListReport = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách khiếu nại' },
];

export const breadcrumAddGroupService = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/group-service/list'}>Danh sách nhóm dịch vụ</Link> },
  { title: 'Tạo mới nhóm dịch vụ' },
];

export const breadcrumDetailGroupServiceV2 = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/v2-group-service/list'}>Danh sách nhóm dịch vụ</Link> },
  { title: 'Chỉnh sửa nhóm dịch vụ' },
];

export const breadcrumAddGroupServiceV2 = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/v2-group-service/list'}>Danh sách nhóm dịch vụ</Link> },
  { title: 'Tạo mới nhóm dịch vụ' },
];

export const breadcrumDetailGroupService = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/group-service/list'}>Danh sách nhóm dịch vụ</Link> },
  { title: 'Chỉnh sửa nhóm dịch vụ' },
];

export const breadcrumbAddCategory = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/service/category'}>Danh sách danh mục dịch vụ</Link> },
  { title: 'Thêm mới danh mục dịch vụ' },
];
export const breadcrumbDetailCategory = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/service/category'}>Danh sách danh mục dịch vụ</Link> },
  { title: 'Chỉnh sửa danh mục dịch vụ' },
];

export const breadcrumbListCategory = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách danh mục dịch vụ' },
];

export const breadcrumListGroupNews = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách nhóm tin tức' },
];

export const breadcrumbAddGroupNews = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/group-news/list'}>Danh sách nhóm tin tức</Link> },
  { title: 'Tạo nhóm tin tức' },
];

export const breadcrumbDetailGroupNews = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/group-news/list'}>Danh sách nhóm tin tức</Link> },
  { title: 'Chi tiết nhóm tin tức' },
];

export const breadcrumListNews = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách bài viết tin tức' },
];

export const breadcrumbAddNews = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/news/list'}>Danh sách tin tức</Link> },
  { title: 'Tạo tin tức' },
];

export const breadcrumbDetailNews = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/news/list'}>Danh sách tin tức</Link> },
  { title: 'Chi tiết tin tức' },
];

export const breadcrumbConfigHome = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/config/home'}>Cấu hình Layout</Link> },
  { title: 'Cấu hình trang chủ' },
];

export const breadcrumbConfigChinhSach = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/config/home'}>Cấu hình Layout</Link> },
  { title: 'Cấu hình trang chính sách' },
];

export const breadcrumbConfigCuaHang = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/config/home'}>Cấu hình Layout</Link> },
  { title: 'Cấu hình trang cửa hàng' },
];

export const breadcrumbConfigCauChuyenThuongHieu = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/config/home'}>Cấu hình Layout</Link> },
  { title: 'Cấu hình trang câu chuyện thương hiệu' },
];

export const breadcrumbConfigCauHoi = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/config/home'}>Cấu hình Layout</Link> },
  { title: 'Cấu hình trang câu hỏi thường gặp' },
];

export const breadcrumbConfigFooter = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/config/home'}>Cấu hình Layout</Link> },
  { title: 'Cấu hình chân trang Footer' },
];

export const breadcrumbConfigTaiKhoan = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/config/home'}>Cấu hình Layout</Link> },
  { title: 'Cấu hình giao diện trang tài khoản' },
];

export const breadcrumbConfigLogin = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/config/home'}>Cấu hình Layout</Link> },
  { title: 'Cấu hình giao diện trang đăng nhập' },
];

export const breadcrumbConfigGiaTriCotLoi = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/config/home'}>Cấu hình Layout</Link> },
  { title: 'Cấu hình giao diện trang giá trị cốt lõi' },
];

export const breadcrumbConfigDatLich = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/config/home'}>Cấu hình Layout</Link> },
  { title: 'Cấu hình giao diện trang đặt lịch' },
];

export const breadcrumbConfigDichVu = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/config/home'}>Cấu hình Layout</Link> },
  { title: 'Cấu hình giao diện trang dịch vụ' },
];

export const breadcrumbConfigDanhMucDichVu = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/config/home'}>Cấu hình Layout</Link> },
  { title: 'Cấu hình giao diện trang danh mục dịch vụ' },
];
