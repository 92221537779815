import React, { useState, useEffect } from 'react';
import { Button, Input, Form, Switch, Select, InputNumber } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { removeVietnamese } from '@utils/Functions/RemoveVietnamese';
import TextArea from 'antd/es/input/TextArea';
import Ckeditor from '@components/Editor/CkeditorEnable';
import UploadMono from '@components/UploadImage/UploadV3';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';
import { v4 as uuidv4 } from 'uuid';
import { convertToSlug } from '@utils/Functions/RemoveVietnamese';
import { handleService, handleNews } from '@services';

export const NewsForm = ({
  initialValues,
  onFinish,
  onFinishFailed,
  content,
  setContent,
}) => {
  const [form] = Form.useForm();
  const [listGroupNews, setListGroupNews] = useState([]);
  const handleChangeName = (value) => {
    form.setFieldsValue({
      slug: convertToSlug(value),
    });
  };

  const handleGetListGroupNews = async () => {
    handleNews
      .DroplistGroupNews()
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        console.log(data, 'data');

        setListGroupNews(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    handleGetListGroupNews();
  }, []);
  return (
    <Form
      form={form}
      key={JSON.stringify(initialValues)}
      initialValues={initialValues}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      className="mb-10 main-add-form"
    >
      <Form.Item
        label="Tiêu đề bài viết"
        name="name"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập tiêu đề bài viết',
          },
        ]}
        className=""
      >
        <Input
          placeholder="Nhập tiêu đề bài viết"
          onChange={(e) => handleChangeName(e.target.value)}
        />
      </Form.Item>
      <Form.Item
        label="Đường dẫn bài viết"
        name="slug"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập đường dẫn bài viết',
          },
        ]}
        className="mb-0"
      >
        <Input placeholder="Nhập đường dẫn bài viết" />
      </Form.Item>
      <Form.Item label="Mô tả" name="description" className="mb-0">
        <TextArea placeholder="Nhập mô tả bài viết" />
      </Form.Item>
      <Form.Item
        label="Nhóm bài viết"
        name="groupId"
        className="mb-0"
        rules={[
          {
            required: true,
            message: 'Vui lòng lựa chọn nhóm bài viết',
          },
        ]}
      >
        <Select
          showSearch
          placeholder="Lựa chọn nhóm bài viết"
          options={listGroupNews}
          optionFilterProp="children"
          filterOption={(input, option: any) =>
            removeVietnamese(option?.label ?? '').includes(
              removeVietnamese(input),
            )
          }
        ></Select>
      </Form.Item>
      <Form.Item
        label="Trạng thái hiển thị"
        name="active"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn trạng thái hoạt động',
          },
        ]}
      >
        <Switch
          checkedChildren="Hiển thị"
          unCheckedChildren="Tạm ẩn"
          defaultChecked
        />
      </Form.Item>
      <Form.Item label="Thứ hạng bài viết" name="indexing" className="mb-0">
        <div className="flex mg-bttom-0">
          <Form.Item label="" name="indexing" className="mb-0">
            <InputNumber min={0} />
          </Form.Item>
          <span className="ml-5 italic text-[#1677FF] mt-1">
            Dùng để ưu tiên hiển thị bài viết trong nhóm
          </span>
        </div>
      </Form.Item>
      <div className="mb-5 ml-10 font-bold">Nội dung bài viết</div>
      <div className="mx-10 mb-5">
        <Ckeditor data={content} setData={setContent} folder={'service'} />
      </div>
      <div className="ml-10 font-bold">Thông tin SEO</div>
      <Form.Item label="Title" name="titleSeo" className="mb-0">
        <Input placeholder="Nhập title SEO" />
      </Form.Item>
      <Form.Item label="Keywork" name="keywordSeo" className="mb-0">
        <Input placeholder="Nhập Keywork SEO" />
      </Form.Item>
      <Form.Item label="Description" name="descriptionSeo" className="mb-0">
        <TextArea placeholder="Nhập mô tả SEO" />
      </Form.Item>
      <div className="flex justify-end">
        <Button type="primary" className="button-submit" htmlType="submit">
          {!initialValues?.config ? 'Tạo mới ' : 'Cập nhật'}
        </Button>
      </div>
    </Form>
  );
};
