import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { notification, Input, Button } from 'antd';
import { handleConfig } from '@services';
import { useNavigate } from 'react-router-dom';
import { breadcrumbConfigDanhMucDichVu as breadcrumbItems } from '@components/Breadcrumb';
import UploadCustomV2 from '@components/UploadImage/UploadMono';
import { CategoryForm } from '@components/Form/Category';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';
import UploadMutipleCustom from '@components/UploadImage/UploadMutiple';
import { v4 as uuidv4 } from 'uuid';
import '../index.scss';
import TextArea from 'antd/es/input/TextArea';
import Ckeditor from '@components/Editor/CkeditorEnable';

export const ConfigDanhMucDichVu = () => {
  const navigate = useNavigate();
  const [imageUrl1, setImageUrl1] = useState([]);
  const [imageUrl2, setImageUrl2] = useState<string>('');
  const [imageUrl3, setImageUrl3] = useState('');
  const [content1, setContent1] = useState<string>('');
  const [content2, setContent2] = useState<string>('');
  const [slideText, setSlideText] = useState<string>('');
  const [slideTextH, setSlideTextH] = useState<string>('');
  const [text1, setText1] = useState<string>('');
  const [text1H, setText1H] = useState<string>('');
  const [text2, setText2] = useState<string>('');
  const [text2H, setText2H] = useState<string>('');

  const title = 'Cấu hình giao diện trang danh mục dịch vụ';
  const titleButton = '';
  const routerAdd = '';
  const forlderName = 'home';
  const handleGetDataDetail = async () => {
    handleConfig
      .GetDetailConfig('DANHMUCDICHVU')
      .then((response: any) => {
        const { statusCode, data } = response;
        if (data && data?.content) {
          const contenJson = JSON.parse(data?.content);
          console.log(contenJson, 'contenJson');
          if (contenJson?.conten1 && contenJson?.conten1.length > 0) {
            const listImageParse = contenJson?.conten1?.map((item, index) => {
              return {
                uid: (index + 1).toString(),
                lastModified: null,
                lastModifiedDate: '',
                name: item,
                size: 490015,
                type: 'image/png',
                percent: 100,
                originFileObj: {
                  uid: index + 1,
                  name: item,
                },
                status: 'done',
                response: item,
                thumbUrl: item,
                xhr: undefined,
              };
            });
            setImageUrl1(listImageParse);
          }
          if (contenJson) {
            setImageUrl2(contenJson?.content3?.image);
            setImageUrl3(contenJson?.content4?.image);
            setContent1(contenJson?.content3?.content);
            setContent2(contenJson?.content4?.content);

            setSlideText(contenJson?.content2?.text);
            setSlideTextH(contenJson?.content2?.textH);

            setText1(contenJson?.content3?.text);
            setText1H(contenJson?.content3?.textH);
            setText2(contenJson?.content4?.text);
            setText2H(contenJson?.content4?.textH);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    handleGetDataDetail();
  }, []);
  const onFinish = () => {
    const newListImages1 = imageUrl1.map((item) => {
      return item.response;
    });
    const bodyValues = {
      conten1: newListImages1,
      content2: {
        text: slideText,
        textH: slideTextH,
      },
      content3: {
        text: text1,
        textH: text1H,
        content: content1,
        image: imageUrl2,
      },
      content4: {
        text: text2,
        textH: text2H,
        content: content2,
        image: imageUrl3,
      },
    };
    const body = {
      name: 'DANHMUCDICHVU',
      content: JSON.stringify(bodyValues),
    };
    console.log(body, 'body');
    handleConfig
      .UpdateConfig(body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Cập nhật dữ liệu thành công',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="px-5 py-5 mt-5 bg-white mb-[50px]">
        <div className="font-bold">
          1. Ảnh Slide Baner (không quá 3mb, tỉ lệ dài : cao - 1440 : 650 )
        </div>
        <UploadMutipleCustom
          fileList={imageUrl1}
          setFileList={setImageUrl1}
          forlderName={forlderName}
        />
        <div className="font-bold">2. Nội dung Text chạy ngang màn hình</div>
        <div className="mt-5">
          <div className="flex items-center justify-between mb-5">
            <Input
              placeholder="Dòng chữ"
              className="mr-5"
              value={slideText}
              onChange={(e) => setSlideText(e.target.value)}
            ></Input>
            <Input
              placeholder="Dòng chữ HighLight"
              value={slideTextH}
              onChange={(e) => setSlideTextH(e.target.value)}
            ></Input>
          </div>
        </div>
        <div className="font-bold">3. Nội dung Block 1</div>
        <div className="flex items-center justify-between">
          <UploadCustomV2
            setImageUrl={setImageUrl2}
            urlImage={imageUrl2}
            imageClass={ImageCalssEnum.Certificate}
            forlderName={forlderName}
          ></UploadCustomV2>
          <div>
            <Input
              placeholder="Nội dung"
              value={text1}
              onChange={(e) => setText1(e.target.value)}
            ></Input>
            <Input
              placeholder="Nội dung hightLight"
              className="my-3"
              value={text1H}
              onChange={(e) => setText1H(e.target.value)}
            ></Input>
            <div className="w-[590px]">
              <Ckeditor
                data={content1}
                setData={setContent1}
                folder={'service'}
              />
            </div>
          </div>
        </div>
        <div className="mb-5 font-bold">4. Nội dung Block 2</div>
        <div className="flex items-center justify-between">
          <div>
            <Input
              placeholder="Nội dung"
              value={text2}
              onChange={(e) => setText2(e.target.value)}
            ></Input>
            <Input
              placeholder="Nội dung hightLight"
              className="my-3"
              value={text2H}
              onChange={(e) => setText2H(e.target.value)}
            ></Input>
            <div className="w-[590px]">
              <Ckeditor
                data={content2}
                setData={setContent2}
                folder={'service'}
              />
            </div>
          </div>
          <UploadCustomV2
            setImageUrl={setImageUrl3}
            urlImage={imageUrl3}
            imageClass={ImageCalssEnum.Certificate}
            forlderName={forlderName}
          ></UploadCustomV2>
        </div>

        <div className="flex justify-center w-full pt-5">
          <Button type="primary" onClick={onFinish}>
            Cập nhật dữ liệu
          </Button>
        </div>
      </div>
    </>
  );
};
