export const getParamsFromUrl = (url) => {
  const urlSearchParams = new URLSearchParams(url.search); // Get parameters from the query string
  const params: any = {};

  for (const [key, value] of urlSearchParams.entries()) {
    params[key] = value;
  }

  // Set default values for pageIndex and pageSize if they are not present or are null/undefined
  params.pageIndex = params.pageIndex || 1;
  params.pageSize = params.pageSize || 10;

  return params;
};
