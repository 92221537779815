import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { notification, Input, Button } from 'antd';
import { handleConfig } from '@services';
import { useNavigate } from 'react-router-dom';
import { breadcrumbConfigGiaTriCotLoi as breadcrumbItems } from '@components/Breadcrumb';
import UploadCustomV2 from '@components/UploadImage/UploadMono';
import { CategoryForm } from '@components/Form/Category';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';
import UploadMutipleCustom from '@components/UploadImage/UploadMutiple';
import { v4 as uuidv4 } from 'uuid';
import '../index.scss';
import TextArea from 'antd/es/input/TextArea';
import Ckeditor from '@components/Editor/CkeditorEnable';

export const ConfigGiaTriCotLoi = () => {
  const navigate = useNavigate();

  const [imageUrl1, setImageUrl1] = useState('');
  const [titlePage, setTitlePage] = useState('');

  const [imageUrl2, setImageUrl2] = useState('');
  const [title2, setTitle2] = useState('');
  const [subTitle2, setSubTitle2] = useState('');
  const [content2, setContent2] = useState('');

  const [imageUrl3, setImageUrl3] = useState('');
  const [title3, setTitle3] = useState('');
  const [subTitle3, setSubTitle3] = useState('');
  const [content3, setContent3] = useState('');

  const [blockContent1, setBlockContent1] = useState('');
  const [blockContent2, setBlockContent2] = useState('');
  const [blockContent3, setBlockContent3] = useState('');

  const [imageUrl5, setImageUrl5] = useState('');
  const [title5, setTitle5] = useState('');
  const [subTitle5, setSubTitle5] = useState('');
  const [content5, setContent5] = useState('');

  const title = 'Cấu hình giao diện trang giá trị cốt lỗi';
  const titleButton = '';
  const routerAdd = '';
  const forlderName = 'home';

  const handleGetDataDetail = async () => {
    handleConfig
      .GetDetailConfig('GIATRICOTLOI')
      .then((response: any) => {
        const { statusCode, data } = response;
        if (data && data?.content) {
          const contenJson = JSON.parse(data?.content);
          console.log(contenJson, 'contenJson');
          if (contenJson) {
            setImageUrl1(contenJson?.content1?.image);
            setTitlePage(contenJson?.content1?.title);

            setImageUrl2(contenJson?.content2?.image);
            setTitle2(contenJson?.content2?.title);
            setSubTitle2(contenJson?.content2?.subTitle);
            setContent2(contenJson?.content2?.content);

            setImageUrl3(contenJson?.content3?.image);
            setTitle3(contenJson?.content3?.title);
            setSubTitle3(contenJson?.content3?.subTitle);
            setContent3(contenJson?.content3?.content);

            setBlockContent1(contenJson?.content4?.content1);
            setBlockContent2(contenJson?.content4?.content2);
            setBlockContent3(contenJson?.content4?.content3);

            setImageUrl5(contenJson?.content5?.image);
            setTitle5(contenJson?.content5?.title);
            setSubTitle5(contenJson?.content5?.subTitle);
            setContent5(contenJson?.content5?.content);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    handleGetDataDetail();
  }, []);
  const onFinish = () => {
    const bodyValues = {
      content1: {
        image: imageUrl1,
        title: titlePage,
      },
      content2: {
        image: imageUrl2,
        title: title2,
        subTitle: subTitle2,
        content: content2,
      },
      content3: {
        image: imageUrl3,
        title: title3,
        subTitle: subTitle3,
        content: content3,
      },
      content4: {
        content1: blockContent1,
        content2: blockContent2,
        content3: blockContent3,
      },
      content5: {
        image: imageUrl5,
        title: title5,
        subTitle: subTitle5,
        content: content5,
      },
    };
    const body = {
      name: 'GIATRICOTLOI',
      content: JSON.stringify(bodyValues),
    };
    console.log(body, 'body');
    handleConfig
      .UpdateConfig(body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Cập nhật dữ liệu thành công',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="px-5 py-5 mt-5 bg-white mb-[50px]">
        <div className="font-bold">
          1. Ảnh Baner & Tiêu đề (không quá 3mb, tỉ lệ dài : cao - 1440 : 430 )
        </div>
        <UploadCustomV2
          setImageUrl={setImageUrl1}
          urlImage={imageUrl1}
          imageClass={ImageCalssEnum.Certificate}
          forlderName={forlderName}
        ></UploadCustomV2>
        <Input
          placeholder="Tiêu đề"
          className="mb-2"
          value={titlePage}
          onChange={(e) => setTitlePage(e.target.value)}
        />
        <div className="mt-5 font-bold">2. Nội dung Block 1</div>
        <div className="flex items-center justify-between">
          <UploadCustomV2
            setImageUrl={setImageUrl2}
            urlImage={imageUrl2}
            imageClass={ImageCalssEnum.Certificate}
            forlderName={forlderName}
          ></UploadCustomV2>
          <div>
            <Input
              placeholder="Nội dung"
              value={title2}
              onChange={(e) => setTitle2(e.target.value)}
            ></Input>
            <Input
              placeholder="Nội dung hightLight"
              className="my-3"
              value={subTitle2}
              onChange={(e) => setSubTitle2(e.target.value)}
            ></Input>
            <div className="w-[590px]">
              <Ckeditor
                data={content2}
                setData={setContent2}
                folder={'service'}
              />
            </div>
          </div>
        </div>
        <div className="mt-5 font-bold">3. Nội dung Block 2</div>
        <div className="flex items-center justify-between mt-3">
          <div>
            <Input
              placeholder="Nội dung"
              value={title3}
              onChange={(e) => setTitle3(e.target.value)}
            ></Input>
            <Input
              placeholder="Nội dung hightLight"
              className="my-3"
              value={subTitle3}
              onChange={(e) => setSubTitle3(e.target.value)}
            ></Input>
            {/* <div className="w-[590px]">
              <Ckeditor
                data={content3}
                setData={setContent3}
                folder={'service'}
              />
            </div> */}
          </div>
          <UploadCustomV2
            setImageUrl={setImageUrl3}
            urlImage={imageUrl3}
            imageClass={ImageCalssEnum.Certificate}
            forlderName={forlderName}
          ></UploadCustomV2>
        </div>
        <div className="mt-5 font-bold">4. Nội dung Block 3</div>
        <div className="flex justify-between my-4">
          <div className="w-[310px]">
            <Ckeditor
              data={blockContent1}
              setData={setBlockContent1}
              folder={'service'}
            />
          </div>
          <div className="w-[310px]">
            <Ckeditor
              data={blockContent2}
              setData={setBlockContent2}
              folder={'service'}
            />
          </div>
          <div className="w-[310px]">
            <Ckeditor
              data={blockContent3}
              setData={setBlockContent3}
              folder={'service'}
            />
          </div>
        </div>
        <div className="mt-5 mb-5 font-bold">5. Nội dung Block 4</div>
        <div className="flex items-center justify-between">
          <UploadCustomV2
            setImageUrl={setImageUrl5}
            urlImage={imageUrl5}
            imageClass={ImageCalssEnum.Certificate}
            forlderName={forlderName}
          ></UploadCustomV2>
          <div>
            <Input
              placeholder="Nội dung"
              value={title5}
              onChange={(e) => setTitle5(e.target.value)}
            ></Input>
            <Input
              placeholder="Nội dung hightLight"
              className="my-3"
              value={subTitle5}
              onChange={(e) => setSubTitle5(e.target.value)}
            ></Input>
            <div className="w-[590px]">
              <Ckeditor
                data={content5}
                setData={setContent5}
                folder={'service'}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center w-full">
          <Button type="primary" onClick={onFinish}>
            Cập nhật dữ liệu
          </Button>
        </div>
      </div>
    </>
  );
};
