export const removeNullAndUndefined = (arr: Array<any>): Array<any> => {
  return arr.filter((item: any) => item !== null && item !== undefined);
};

export const convertStringToList = (inputString) => {
  if (!inputString) return [];
  // Loại bỏ các ký tự không mong muốn từ chuỗi đầu vào
  const cleanedString = inputString
    .replace('[', '')
    .replace(']', '')
    .replace(/ /g, '');

  // Tách chuỗi thành danh sách bằng cách sử dụng dấu phẩy làm dấu phân cách
  const imageList = cleanedString.split(',');

  return imageList;
};

export const convertListToString = (stringArray) => {
  // Chuyển mảng chuỗi thành chuỗi bằng cách sử dụng dấu phẩy làm dấu phân cách
  const joinedString = stringArray.join(', ');

  // Thêm dấu ngoặc vuông vào đầu và cuối chuỗi
  const resultString = `[${joinedString}]`;

  return resultString;
};
