import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { notification } from 'antd';
import { handleService } from '@services';
import { useNavigate, useParams } from 'react-router-dom';
import { breadcrumDetailGroupServiceV2 as breadcrumbItems } from '@components/Breadcrumb';
import { GroupServicesV2Form } from '@components/Form/GroupServicesV2';

export const DetailGroupServicesV2 = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [content, setContent] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [initialValues, setInitialValues] = useState<any>({ config: true });

  const [title, setTitle] = useState('Chỉnh sửa nhóm dịch vụ');
  const titleButton = '';
  const routerAdd = '';
  const handleGetGroupServiceDetail = async (id) => {
    handleService
      .DetailGroupV2(id)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setTitle(data.name);
          setInitialValues({
            name: data.name,
            text: data.content?.text,
            hightlight: data.content?.hightlight,
            show_home: data.show_home,
            minium: data.minium,
            active: data.active,
            description: data.description,
            config: true,
          });
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (id) {
      handleGetGroupServiceDetail(id);
    }
  }, [id]);

  const onFinish = (values: any) => {
    const body = {
      name: values.name,
      active: values.activeBooking,
      show_home: values.show_home,
      minium: values.minium,
      description: values.description,
      content: {
        text: values.text,
        hightlight: values.hightlight,
      },
    };
    console.log(body, 'body');
    handleService
      .UpdateGroupV2(body, id)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Bạn đã cập nhật dữ liệu nhóm dịch vụ thành công',
          });
          handleGetGroupServiceDetail(id);
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Có lỗi xảy ra trong quá trình xử lý dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="main main-add">
        <div className="w-full pt-10 bg-white">
          <GroupServicesV2Form
            initialValues={initialValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          />
        </div>
      </div>
    </>
  );
};
