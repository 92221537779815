// src/store/userSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../index';

interface RoleDto {
  permission: Array<any>;
  permissionName: string;
  role: string;
}

interface UserInforDto {
  id: string;
  name: string;
  userName: string;
}
interface User {
  UserInfor: UserInforDto;
  Role: RoleDto;
}

const initialState: User = {
  UserInfor: null,
  Role: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setInfor: (state, action) => {
      state.Role = action.payload?.Role;
      state.UserInfor = action.payload?.UserInfor;
    },
    clearInfor: (state) => {
      state.UserInfor = null;
      state.Role = null;
    },
  },
});

export const { setInfor, clearInfor } = userSlice.actions;
export default userSlice.reducer;
