import { instance } from '@configs/instance';

const getListGroupNews = (param: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_MARKETING + `/news/group-news-list`,
    {
      params: param,
    },
  );
};

const createGroupNews = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_MARKETING + `/news/create-group`,
    body,
  );
};

const updateGroupNews = (body: any, id: any): Promise<any> => {
  return instance.put(
    process.env.REACT_APP_API_MARKETING + `/news/update-group/${id}`,
    body,
  );
};

const deleteGroupNews = (id: any): Promise<any> => {
  return instance.delete(
    process.env.REACT_APP_API_MARKETING + `/news/delete-group/${id}`,
  );
};

const onTopGroupNews = (id: any): Promise<any> => {
  return instance.patch(
    process.env.REACT_APP_API_MARKETING + `/news/ontop-group/${id}`,
  );
};

const detailGroupNews = (id: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_MARKETING + `/news/detail-group/${id}`,
  );
};

const droplistGroupNews = (): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_MARKETING + `/news/drop-list-group`,
  );
};

// Tin tức
const getListNews = (param: any): Promise<any> => {
  return instance.get(process.env.REACT_APP_API_MARKETING + `/news/list`, {
    params: param,
  });
};

const createNews = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_MARKETING + `/news/create`,
    body,
  );
};

const deleteNews = (id: any): Promise<any> => {
  return instance.delete(
    process.env.REACT_APP_API_MARKETING + `/news/delete/${id}`,
  );
};

const onTopNews = (id: any): Promise<any> => {
  return instance.patch(
    process.env.REACT_APP_API_MARKETING + `/news/ontop/${id}`,
  );
};

const detailNews = (id: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_MARKETING + `/news/detail/${id}`,
  );
};

const updateNews = (body: any, id: any): Promise<any> => {
  return instance.put(
    process.env.REACT_APP_API_MARKETING + `/news/update/${id}`,
    body,
  );
};

export default {
  getListGroupNews,
  createGroupNews,
  updateGroupNews,
  deleteGroupNews,
  onTopGroupNews,
  detailGroupNews,
  droplistGroupNews,
  getListNews,
  createNews,
  deleteNews,
  onTopNews,
  detailNews,
  updateNews,
};
