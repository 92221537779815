import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { notification, Button, Input } from 'antd';
import { handleCategory, handleService, handlePromotion } from '@services';
import { useNavigate, useParams } from 'react-router-dom';
import { breadcrumbDetailService as breadcrumbItems } from '@components/Breadcrumb';
import UploadMutipleCustom from '@components/UploadImage/UploadMutiple';
import { ServiceForm } from '@components/Form/Service';
import { removeNullAndUndefined } from '@utils/Functions/configArray';
import { v4 as uuidv4 } from 'uuid';
import { convertToSlug } from '@utils/Functions/RemoveVietnamese';
import {
  convertStringToList,
  convertListToString,
} from '@utils/Functions/configArray';
import { Link } from 'react-router-dom';
import '../../index.scss';

export const DetailService = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [content, setContent] = useState<any>('');
  const [initialValues, setInitialValues] = useState<any>({ config: true });
  const [listData, setListData] = useState<any>([
    {
      id: uuidv4(),
      image: '',
      title: '',
      text: '',
    },
  ]);
  const [imageUrl, setImageUrl] = useState([]);
  const [idSQL, setIdSQL] = useState();
  const [listVoucher, setListVoucher] = useState([]);
  const [title, setTitle] = useState('Chỉnh sửa thông tin dịch vụ');
  const titleButton = '';
  const routerAdd = '';
  const forlderName = 'category';

  const [voucherId, setVoucherId] = useState<any>();
  const handleGetDataSalonDetail = async (serviceid) => {
    handleService
      .GetDetailService(serviceid)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setTitle(data.name);
          setIdSQL(data.idSQL);
          setInitialValues({
            name: data.name,
            slug: data.slug ? data.slug : convertToSlug(data.name),
            code: data.code,
            cost: data.cost,
            combo: data.combo,
            active_booking: data.active_booking,
            active: data.active,
            category_idSQL: data.category_idSQL,
            description: data.description,
            time: data.time,
            titleSeo: data.seo?.title,
            keywordSeo: data.seo?.keyword,
            descriptionSeo: data.seo?.description,
            config: true,
          });
          if (data.image_url) {
            console.log(data.image_url, 'data.image_url');
            const arrayImage = convertStringToList(data.image_url);
            const listNews = arrayImage.map((item, index) => {
              return {
                uid: (index + 1).toString(),
                lastModified: null,
                lastModifiedDate: '',
                name: item,
                size: 490015,
                type: 'image/png',
                percent: 100,
                originFileObj: {
                  uid: index + 1,
                  name: item,
                },
                status: 'done',
                response: item,
                thumbUrl: item,
                xhr: undefined,
              };
            });
            setImageUrl(listNews);
          }
          setContent(data.content);
          setVoucherId(data.voucherId);
          if (data.procedure) {
            setListData(data.procedure);
          }
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (id) {
      handleGetDataSalonDetail(id);
    }
  }, [id]);
  // useEffect(() => {
  //   if (idSQL) {
  //     GetListPromotion(idSQL);
  //   }
  // }, [idSQL]);
  const onFinish = (values: any) => {
    const newListImages = imageUrl.map((item) => {
      return item.response;
    });
    const body = {
      name: values.name,
      slug: values.slug,
      seo: {
        title: values.titleSeo,
        keyword: values.keywordSeo,
        description: values.descriptionSeo,
      },
      procedure: listData,
      content: content,
      active_booking: values.active_booking,
      active: values.active,
      voucherId: voucherId,
      image_url:
        removeNullAndUndefined(newListImages).length > 0
          ? convertListToString(removeNullAndUndefined(newListImages))
          : undefined,
    };
    console.log(body, 'body');
    handleService
      .UpdateService(id, body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          handleGetDataSalonDetail(id);
          notification.success({
            message: 'Thành công',
            description: 'Câp nhật thông tin danh mục dịch vụ thành công',
          });
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Có lỗi xảy ra trong quá trình xử lý dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  // const GetListPromotion = async (servicesIdSQL) => {
  //   handlePromotion
  //     .GetListService({ serviceIds: servicesIdSQL })
  //     .then((response: any) => {
  //       const { data, statusCode } = response;
  //       if (statusCode === 200) {
  //         setListVoucher(data);
  //       } else {
  //         notification.error({
  //           message: 'Lỗi',
  //           description: 'Có lỗi xảy ra trong quá trình xử lý dữ liệu !!',
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };
  const handleChoseVoucher = (id) => {
    if (id === voucherId) {
      setVoucherId(null);
    } else {
      setVoucherId(id);
    }
  };
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="main main-add">
        <div className="main-left">
          <ServiceForm
            initialValues={initialValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            content={content}
            setContent={setContent}
            listData={listData}
            setListData={setListData}
          />
        </div>
        <div className="main-right">
          <div className="main-right-wrap-card">
            <h4 className="main-right-wrap-card-title">
              <strong>Ảnh dịch vụ </strong> (không quá 5 ảnh)
            </h4>
            <UploadMutipleCustom
              fileList={imageUrl}
              setFileList={setImageUrl}
              forlderName={forlderName}
            />
          </div>
          {/* <div className="main-right-wrap-card">
            <h4 className="mb-2 main-right-wrap-card-title">
              <strong>Áp ưu đãi vào dịch vụ</strong> (Chỉ áp 1 ưu đãi)
            </h4>
            <div className="pb-1">
              {listVoucher.map((item, index) => (
                <div
                  key={index}
                  className={
                    item.id === voucherId
                      ? 'relative flex px-2 py-3 border-[#dedede] border-[0.8px] mb-3 mx-2 rounded-[5px] cursor-pointer bg-[#1677FF30]'
                      : 'flex px-2 py-3 border-[#dedede] border-[0.8px] mb-3 mx-2 rounded-[5px] cursor-pointer'
                  }
                  onClick={() => handleChoseVoucher(item.id)}
                >
                  <img
                    className="w-[90px] h-[70px]"
                    alt="image"
                    src={item.imageUrl}
                  />
                  <div className="flex flex-col justify-between flex-1 ml-3 mr-2">
                    <div className="">{item.name}</div>
                    <div className="flex justify-end">
                      <Link
                        to={`/promotion/${item.id}`}
                        className=" text-[#1677FF] text-[12px]"
                      >
                        Xem chi tiết
                      </Link>
                    </div>
                  </div>
                  <div
                    className={
                      item.id === voucherId
                        ? 'absolute right-0 top-0 bg-red-600 text-white text-[10px] px-2 py-1'
                        : 'hidden'
                    }
                  >
                    Áp ưu đãi
                  </div>
                </div>
              ))}
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};
