import React from 'react';
import { Breadcrumb as AntdBreadcrumb, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import './index.scss';

const TitleBox = ({ breadcrumbItems, title, titleButton, routerAdd }) => {
  return (
    <div className="titlebox">
      <AntdBreadcrumb items={breadcrumbItems} className="titlebox-breadcrumb" />
      <div className="flex items-center mt-3 titlebox-body">
        <h2 className="text-2xl font-bold titlebox-body-title">{title}</h2>
        {titleButton && (
          <Link to={routerAdd}>
            <Button type="primary" className="titlebox-body-btn">
              <PlusOutlined className="relative top-[-2.5px]" />
              {titleButton}
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default TitleBox;
