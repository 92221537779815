import { instance, instanceExcel, instanceClient } from './instance';
import { setToken } from '@store/auth/authSlice';
import { setInfor, clearInfor } from '@store/auth/userSlice';
import Cookies from 'js-cookie';

let isRefreshing = false;

export const setupInstance = (store) => {
  const { dispatch } = store;
  instance.interceptors.request.use(
    async (config) => {
      const accessTokenState = store.getState().auth.accessToken;

      if (accessTokenState) {
        config.headers['Authorization'] = 'Bearer ' + accessTokenState;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  instance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (err.response) {
        if (err.response.status === 403) {
          // thực hiện việc logout
          Cookies.remove('refreshToken');
          Cookies.remove('accessToken');
          dispatch(clearInfor());
          dispatch(setToken(null));
          window.location.href =
            process.env.REACT_APP_SSO_DOMAIN +
            '?redirect=' +
            window.location.href;
        }
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          if (!isRefreshing) {
            isRefreshing = true;

            try {
              // const accessTokenCookies = Cookies.get('accessToken');
              const refreshTokenCookies = Cookies.get('refreshToken');

              const rs = await instanceClient.post(
                `${process.env.REACT_APP_API_AUTH}/api/v1/staff/refresh-token`,
                {},
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + refreshTokenCookies,
                  },
                },
              );

              const { accessToken, refreshToken } = rs.data?.data;
              Cookies.set('accessToken', accessToken);
              Cookies.set('refreshToken', refreshToken);
              dispatch(setToken(accessToken));

              // Update the original request with the new token
              originalConfig.headers['Authorization'] = 'Bearer ' + accessToken;
              console.log(originalConfig, 'originalConfig');

              // Retry the original request
              return instance(originalConfig);
            } catch (_error) {
              // thực hiện việc logout
              Cookies.remove('refreshToken');
              Cookies.remove('accessToken');
              dispatch(clearInfor());
              dispatch(setToken(null));
              window.location.href =
                process.env.REACT_APP_SSO_DOMAIN +
                '?redirect=' +
                window.location.href;
            } finally {
              isRefreshing = false;
            }
          }
        }
      }

      return Promise.reject(err);
    },
  );
};

export const setupInstanceExcel = (store) => {
  const { dispatch } = store;

  instanceExcel.interceptors.request.use(
    async (config) => {
      const accessTokenState = store.getState().auth.accessToken;

      if (accessTokenState) {
        config.headers['Authorization'] = 'Bearer ' + accessTokenState;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  instanceExcel.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== '/v1/auth/login-admin' && err.response) {
        if (err.response.status === 403) {
          // thực hiện việc logout
          Cookies.remove('refreshToken');
          Cookies.remove('accessToken');
          dispatch(clearInfor());
          dispatch(setToken(null));
          // window.location.href = '/login';
        }
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          if (!isRefreshing) {
            isRefreshing = true;

            try {
              const accessTokenCookies = Cookies.get('accessToken');
              const refreshTokenCookies = Cookies.get('refreshToken');

              const rs = await instance.put(
                `${process.env.REACT_APP_BASE_URL}/api/v1/auth/refresh-token`,
                {},
                {
                  headers: {
                    'Content-Type': 'application/json',
                    accesstoken: accessTokenCookies,
                    refreshtoken: refreshTokenCookies,
                  },
                },
              );

              const { accessToken, refreshToken } = rs.data?.data;
              Cookies.set('accessToken', accessToken);
              Cookies.set('refreshToken', refreshToken);
              dispatch(setToken(accessToken));

              // Update the original request with the new token
              originalConfig.headers['Authorization'] = 'Bearer ' + accessToken;
              console.log(originalConfig, 'originalConfig');

              // Retry the original request
              return instanceExcel(originalConfig);
            } catch (_error) {
              // thực hiện việc logout
              Cookies.remove('refreshToken');
              Cookies.remove('accessToken');
              dispatch(clearInfor());
              dispatch(setToken(null));
              // window.location.href = '/login';
            } finally {
              isRefreshing = false;
            }
          }
        }
      }

      return Promise.reject(err);
    },
  );
};
