import { instance, axiosInstanceClient } from '@configs/instance';

const getListRegionRSM = (): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_ERP + `/api/region-manager/droplist-region-rsm`,
  );
};
const getListRegionASM = (rsmId): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_ERP +
      `/api/region-manager/droplist-region-asm/${rsmId}`,
  );
};

const getListCitys = (): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_ERP + `/api/resources/provinces`,
  );
};

const getListDistricts = (cityCode: string): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_ERP + `/api/resources/provinces/${cityCode}`,
  );
};

const getListWards = (districtCode: string): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_ERP + `/api/resources/district/${districtCode}`,
  );
};

const getLatLongAPI = (address: string): Promise<any> => {
  return axiosInstanceClient.get(
    process.env.REACT_APP_API_GEOAPIFY + `/v1/geocode/search`,
    {
      params: {
        format: 'json',
        apiKey: process.env.REACT_APP_GEOAPIFY_KEY,
        text: address,
      },
    },
  );
};

export default {
  getListRegionRSM,
  getListRegionASM,
  getListCitys,
  getListDistricts,
  getListWards,
  getLatLongAPI,
};
