import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { notification, Input, Button } from 'antd';
import { handleConfig } from '@services';
import { useNavigate } from 'react-router-dom';
import { breadcrumbConfigLogin as breadcrumbItems } from '@components/Breadcrumb';
import UploadCustomV2 from '@components/UploadImage/UploadMono';
import { CategoryForm } from '@components/Form/Category';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';
import UploadMutipleCustom from '@components/UploadImage/UploadMutiple';
import { v4 as uuidv4 } from 'uuid';
import '../index.scss';
import TextArea from 'antd/es/input/TextArea';

export const ConfigLogin = () => {
  const navigate = useNavigate();
  const [imageUrl1, setImageUrl1] = useState('');
  const [imageUrl2, setImageUrl2] = useState('');
  const [imageUrl3, setImageUrl3] = useState('');

  const title = 'Cấu hình giao diện trang đăng nhập';
  const titleButton = '';
  const routerAdd = '';
  const forlderName = 'home';

  const handleGetDataDetail = async () => {
    handleConfig
      .GetDetailConfig('LOGIN')
      .then((response: any) => {
        const { statusCode, data } = response;
        if (data && data?.content) {
          const contenJson = JSON.parse(data?.content);
          console.log(contenJson, 'contenJson');
          if (contenJson) {
            setImageUrl1(contenJson?.content1?.image);
            setImageUrl2(contenJson?.content2?.image);
            setImageUrl3(contenJson?.content3?.image);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    handleGetDataDetail();
  }, []);
  const onFinish = () => {
    const bodyValues = {
      content1: {
        image: imageUrl1,
      },
      content2: {
        image: imageUrl2,
      },
      content3: {
        image: imageUrl3,
      },
    };
    const body = {
      name: 'LOGIN',
      content: JSON.stringify(bodyValues),
    };
    console.log(body, 'body');
    handleConfig
      .UpdateConfig(body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Cập nhật dữ liệu thành công',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="px-5 py-5 mt-5 bg-white mb-[50px]">
        <div className="font-bold">
          1. Ảnh màn Đăng nhập (không quá 3mb - tỉ lệ dài : cao - 970 : 700)
        </div>
        <div className="flex items-center justify-between">
          <UploadCustomV2
            setImageUrl={setImageUrl1}
            urlImage={imageUrl1}
            imageClass={ImageCalssEnum.Certificate}
            forlderName={forlderName}
          ></UploadCustomV2>
        </div>
        <div className="mt-5 font-bold">
          2. Ảnh các màn quên mật khẩu (không quá 3mb - tỉ lệ dài : cao - 970 :
          700)
        </div>
        <div className="flex items-center justify-between">
          <UploadCustomV2
            setImageUrl={setImageUrl2}
            urlImage={imageUrl2}
            imageClass={ImageCalssEnum.Certificate}
            forlderName={forlderName}
          ></UploadCustomV2>
        </div>

        <div className="mt-5 font-bold">
          1. Ảnh các màn đăng ký (không quá 3mb - tỉ lệ dài : cao - 970 : 700)
        </div>
        <div className="flex items-center justify-between">
          <UploadCustomV2
            setImageUrl={setImageUrl3}
            urlImage={imageUrl3}
            imageClass={ImageCalssEnum.Certificate}
            forlderName={forlderName}
          ></UploadCustomV2>
        </div>
        <div className="flex justify-center w-full">
          <Button type="primary" onClick={onFinish}>
            Cập nhật dữ liệu
          </Button>
        </div>
      </div>
    </>
  );
};
