import { instance } from '@configs/instance';

const uploadV1 = (formData: FormData): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_UPLOAD_DOMAIN + `/images`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export default {
  uploadV1,
};
