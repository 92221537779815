import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { notification } from 'antd';
import { handleNews } from '@services';
import { useNavigate } from 'react-router-dom';
import { breadcrumbAddGroupNews as breadcrumbItems } from '@components/Breadcrumb';
import UploadCustomV2 from '@components/UploadImage/UploadMono';
import { GroupNewsForm } from '@components/Form/GroupNews';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';
import '../../index.scss';

export const AddGroupNews = () => {
  const navigate = useNavigate();
  const initialValues = {
    active: true,
    activeBooking: true,
    config: false,
  };

  const [imageUrl, setImageUrl] = useState<string>('');

  const title = 'Tạo mới nhóm tin tức';
  const titleButton = '';
  const routerAdd = '';
  const forlderName = 'category';

  const onFinish = (values: any) => {
    const body = {
      name: values.name,
      active: values.active,
      description: values.description,
    };
    console.log(body, 'body');
    handleNews
      .CreateGroupNews(body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Bạn đã tạo thành công nhóm bài viết tin tức',
          });
          navigate('/group-news/list?pageSize=10&pageIndex=1');
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Có lỗi xảy ra trong quá trình xử lý dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="px-10 py-10 mt-5 bg-white">
        <GroupNewsForm
          initialValues={initialValues}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        />
      </div>
    </>
  );
};
