import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, notification, Spin } from 'antd';
import { handleService } from '@services';
import TabelComponent from '@components/Table';
import { breadcrumbListService as breadcrumbItems } from '@components/Breadcrumb';
import { ColumnsListServicesV2 as columns } from '@components/Table/Columns';
import { getParamsFromUrl } from '@utils/Functions/getParams';
import { FilterConfig } from '@components/Filter';
import '../../index.scss';

export const ListServicesV2 = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(20);
  const [open, setOpen] = useState(false);
  const [openExcel, setOpenExcel] = useState(false);
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState<any>();
  const [tableLoading, setTableLoading] = useState(false);

  const [listGroup, setListGroup] = useState([]);

  const handleListGroup = async () => {
    handleService
      .DroplistGroupV2()
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        setListGroup(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    handleListGroup();
  }, []);

  const title = 'Danh sách dịch vụ';
  const titleButton = '';
  const routerAdd = '';
  const handleGetListServices = async () => {
    const urlParams = getParamsFromUrl(location);
    setTableLoading(true);
    handleService
      .GetListServicesV2(urlParams)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        console.log(data, 'data');
        if (statusCode === 200) {
          setData(data);
          setCurrentPage(Number(optional?.pageIndex));
          setTotalItems(Number(optional?.getTotalElements));
          setTableLoading(false);
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
          setTableLoading(false);
        }
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error);
      });
  };
  useEffect(() => {
    if (
      location &&
      queryParams.get('pageIndex') &&
      queryParams.get('pageSize')
    ) {
      handleGetListServices();
    }
  }, [location]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const params = new URLSearchParams(window.location.search);
    if (params.has('pageIndex')) {
      params.set('pageIndex', page);
    } else {
      params.append('pageIndex', page);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };

  const showModal = (data) => {
    setModalData(data);
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
    setModalData(null);
  };

  const handleEdit = (record: any) => {
    console.log(record, 'record');
    navigate(`/v2-service/${record._id}`);
  };

  const [dataFilter, setDataFilter] = useState([]);
  const keyName = 'LISTSERVICEV2COLUMN';
  useEffect(() => {
    setDataFilter([
      {
        value: '1',
        label: 'Tên/mã dịch vụ',
        type: 'input',
        name: 'name',
        placeholder: 'Nhập tên/mã dịch vụ',
      },
      {
        value: '2',
        label: 'Danh mục dịch vụ',
        type: 'select',
        name: 'groupId',
        list: listGroup,
        placeholder: 'Chọn danh mục dịch vụ',
      },
      {
        value: '3',
        label: 'Trạng thái hiển thị',
        type: 'select',
        name: 'active',
        list: [
          {
            value: true,
            label: 'Hiển thị',
          },
          {
            value: false,
            label: 'Tạm ẩn',
          },
        ],
        placeholder: 'Chọn trạng thái hiển thị',
      },
    ]);
  }, [listGroup]);

  const customTotal = () => {
    return (
      <span className="pagination-table-title">Tổng {totalItems} dịch vụ</span>
    );
  };

  const handleOnTop = (record) => {
    handleService
      .OnTopServiceV2(record._id)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        console.log(data, 'data');
        if (statusCode === 200) {
          handleGetListServices();
          notification.success({
            message: 'Thành công',
            description: 'Xếp dịch vụ lên đầu thành công',
          });
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
          setTableLoading(false);
        }
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error);
      });
  };
  const handleDelete = (record) => {
    handleService
      .DeleteServiceV2(record._id)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        console.log(data, 'data');
        if (statusCode === 200) {
          handleGetListServices();
          notification.success({
            message: 'Thành công',
            description: 'Xoá dịch vụ thành công',
          });
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
          setTableLoading(false);
        }
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error);
      });
  };
  return (
    <Spin spinning={loading}>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <FilterConfig
        dataFilter={dataFilter}
        setDataFilter={setDataFilter}
        keyName={keyName}
        tableLoading={tableLoading}
      />

      <TabelComponent
        columns={columns({
          handleEdit,
          handleOnTop,
          handleDelete,
        })}
        data={data}
        tableLoading={tableLoading}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalItems={totalItems}
        customTotal={customTotal}
      />
    </Spin>
  );
};
