import React from 'react';
import { Button, Dropdown, Image, Menu, Switch } from 'antd';
import { DataType, ColumnsRendererProps } from './typeTabel';
import { Link } from 'react-router-dom';
import {
  DownOutlined,
  UserOutlined,
  DollarOutlined,
  FileAddOutlined,
  FileSearchOutlined,
  CameraOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { useParams, useNavigate } from 'react-router-dom';
import { formatDate } from '@utils/Functions/convertDate';
import dayjs from 'dayjs';

export const ColumnsListCategory = ({
  handleEdit,
  handleDelete,
}: ColumnsRendererProps) => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return (
          <span>
            {PageIndex
              ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
              : i + 1}
          </span>
        );
      },
    },
    {
      title: 'Hình ảnh',
      key: 'clinicCode',
      render: (_, record) => (
        <>
          <img
            src={record.imageUrl}
            alt="image"
            className="w-[30px] h-[30px]"
          />
        </>
      ),
    },
    {
      title: 'Tên danh mục',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Hiện Booking',
      key: 'address',
      render: (_, record) => (
        <>
          {record.activeBooking ? (
            <span className="text-green-600">Hiện</span>
          ) : (
            <span className="text-red-600">Ẩn</span>
          )}
        </>
      ),
    },
    {
      title: 'Trạng thái',
      key: 'address',
      render: (_, record) => (
        <>
          {record.active ? (
            <span className="text-green-600">Hiển thị</span>
          ) : (
            <span className="text-red-600">Ẩn</span>
          )}
        </>
      ),
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record: DataType) => (
        <>
          <Button
            style={{ marginRight: 10 }}
            onClick={() => handleEdit(record)}
          >
            Sửa
          </Button>
          <Button onClick={() => handleDelete(record)}>Xóa</Button>
        </>
      ),
    },
  ];

  return columns;
};

export const ColumnsListServices = ({
  handleEdit,
  handleChangeBooking,
  handleChangeStatus,
}) => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return (
          <span>
            {PageIndex
              ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
              : i + 1}
          </span>
        );
      },
    },
    {
      title: 'Mã dịch vụ',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Tên dịch vụ',
      key: 'name',
      render: (_, record) => (
        <>
          <div>{record.name}</div>{' '}
          {!record.slug && (
            <div className="text-white bg-red-500 text-[10px] w-[100px] text-center rounded-[5px]">
              Chưa có bài viết
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Giá',
      key: 'cost',
      render: (_, record) => (
        <>
          <span>{record.cost ? record.cost?.toLocaleString('de-DE') : ''}</span>
        </>
      ),
    },
    {
      title: 'Thời gian',
      key: 'time',
      render: (_, record) => (
        <>
          <span>{record.time} phút</span>
        </>
      ),
    },
    {
      title: 'Hiện Booking',
      key: 'activeBooking',
      render: (_, record) => (
        <>
          {/* <Switch
            checkedChildren="Hiện"
            unCheckedChildren="Ẩn"
            value={record.active_booking}
            onChange={() => handleChangeBooking(record)}
          ></Switch> */}
          {record?.active_booking ? (
            <div className="text-green-600">Hiện Booking</div>
          ) : (
            <div className="text-red-600">Ẩn Booking</div>
          )}
        </>
      ),
    },
    {
      title: 'Trạng thái',
      key: 'active',
      render: (_, record) => (
        <>
          {/* <Switch
            checkedChildren="Hiện"
            unCheckedChildren="Ẩn"
            value={record.active}
            onChange={() => handleChangeStatus(record)}
          ></Switch> */}
          {record?.active ? (
            <div className="text-green-600">Hiển thị</div>
          ) : (
            <div className="text-red-600">Không hiển thị</div>
          )}
        </>
      ),
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record: DataType) => (
        <div className="flex">
          <Button
            type="primary"
            style={{ marginRight: 8 }}
            onClick={() => handleEdit(record)}
          >
            Sửa
          </Button>
        </div>
      ),
    },
  ];

  return columns;
};

export const ColumnsListGroupServices = ({
  handleEdit,
  handleOnTop,
  handleUpdateStatus,
}) => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return (
          <span>
            {PageIndex
              ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
              : i + 1}
          </span>
        );
      },
    },
    {
      title: 'Tên nhóm dịch vụ',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Hiện Booking',
      key: 'activeBooking',
      render: (_, record) => (
        <>
          <Switch
            checkedChildren="Hiện"
            unCheckedChildren="Ẩn"
            value={record.activeBooking}
            onChange={() => {
              const body = {
                activeBooking: !record.activeBooking,
              };
              handleUpdateStatus(record._id, body);
            }}
          ></Switch>
        </>
      ),
    },
    {
      title: 'Hiện Trang chủ',
      key: 'showHome',
      render: (_, record) => (
        <>
          <Switch
            checkedChildren="Hiện"
            unCheckedChildren="Không hiện"
            value={record.showHome}
            onChange={() => {
              const body = {
                showHome: !record.showHome,
              };
              handleUpdateStatus(record._id, body);
            }}
          ></Switch>
        </>
      ),
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record: DataType, i) => (
        <div className="flex">
          <Button
            type="primary"
            style={{ marginRight: 8 }}
            onClick={() => handleEdit(record)}
          >
            Sửa
          </Button>
          {(PageIndex
            ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
            : i + 1) !== 1 && (
            <Button type="default" onClick={() => handleOnTop(record)}>
              Lên đầu
            </Button>
          )}
        </div>
      ),
    },
  ];

  return columns;
};

export const ColumnsListReport = () => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return (
          <span>
            {PageIndex
              ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
              : i + 1}
          </span>
        );
      },
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'SĐT liên hệ',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Shop',
      dataIndex: 'salonId',
      key: 'salonId',
    },
    {
      title: 'Nội dung',
      dataIndex: 'content',
      key: 'content',
    },
  ];

  return columns;
};

export const ColumnsListVoucher = ({
  handleEdit,
  handleDelete,
  hanldeChangeBooking,
}) => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return (
          <span>
            {PageIndex
              ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
              : i + 1}
          </span>
        );
      },
    },
    {
      title: 'Mã ưu đãi',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Tên ưu đãi',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Ngày bắt đầu',
      key: 'startDate',
      render: (_, record) => <>{formatDate(record.startDate)}</>,
    },
    {
      title: 'Ngày kết thúc',
      key: 'endDate',
      render: (_, record) => <>{formatDate(record.endDate)}</>,
    },
    {
      title: 'Giảm giá',
      key: 'discountValue',
      render: (_, record) => (
        <>
          {record.discountType === 1
            ? record.discountValue + ' %'
            : record.discountValue.toLocaleString('de-DE') + ' đ'}
        </>
      ),
    },
    {
      title: 'Hiện Booking',
      key: 'activeBooking',
      render: (_, record) => (
        <>
          <Switch
            checkedChildren="Hiện"
            unCheckedChildren="Ẩn"
            onChange={() => {
              const body = {
                id: record.id,
                active: record.active,
                activeBooking: !record.activeBooking,
              };
              hanldeChangeBooking(body);
            }}
            value={record.activeBooking}
          ></Switch>
        </>
      ),
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record: DataType) => (
        <>
          <Button
            style={{ marginRight: 10 }}
            onClick={() => handleEdit(record)}
          >
            Sửa
          </Button>
          <Button onClick={() => handleDelete(record)}>Xóa</Button>
        </>
      ),
    },
  ];

  return columns;
};

export const ColumnsListGroupNews = ({
  handleEdit,
  handleOnTop,
  handleDelete,
}) => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'Tiêu đề nhóm',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Trạng thái',
      key: 'description',
      render: (_, record: any, i) => (
        <>
          {record?.active ? (
            <span className="text-green-600">Hoạt động</span>
          ) : (
            <span className="text-red-600">Tạm ẩm</span>
          )}
        </>
      ),
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record: DataType, i) => (
        <div className="flex">
          <Button
            type="primary"
            style={{ marginRight: 8 }}
            onClick={() => handleEdit(record)}
          >
            Sửa
          </Button>
          <Button
            type="default"
            style={{ marginRight: 8 }}
            onClick={() => handleDelete(record)}
          >
            Xoá
          </Button>
          {(PageIndex
            ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
            : i + 1) !== 1 && (
            <Button type="default" onClick={() => handleOnTop(record)}>
              Lên đầu
            </Button>
          )}
        </div>
      ),
    },
  ];

  return columns;
};

export const ColumnsListNews = ({ handleEdit, handleOnTop, handleDelete }) => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'Hình ảnh',
      key: 'image',
      render: (_, record) => (
        <>
          <img alt="Facialbar" width="70px" src={record?.image} />
        </>
      ),
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Ngày viết',
      key: 'createdAt',
      render: (_, record: any, i) => (
        <span className="">
          {dayjs(record?.createdAt).format('DD/MM/YYYY')}
        </span>
      ),
    },
    {
      title: 'Danh mục',
      key: 'group',
      render: (_, record) => (
        <>{record?.group && <span>{record?.group[0]?.name}</span>}</>
      ),
    },
    {
      title: 'Trạng thái',
      key: 'description',
      render: (_, record: any, i) => (
        <>
          {record?.active ? (
            <span className="text-green-600">Hiển thị</span>
          ) : (
            <span className="text-red-600">Tạm ẩm</span>
          )}
        </>
      ),
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record: DataType, i) => (
        <div className="flex">
          <Button
            type="primary"
            style={{ marginRight: 8 }}
            onClick={() => handleEdit(record)}
          >
            Sửa
          </Button>
          <Button
            type="default"
            style={{ marginRight: 8 }}
            onClick={() => handleDelete(record)}
          >
            Xoá
          </Button>
          {(PageIndex
            ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
            : i + 1) !== 1 && (
            <Button type="default" onClick={() => handleOnTop(record)}>
              Lên đầu
            </Button>
          )}
        </div>
      ),
    },
  ];

  return columns;
};

export const ColumnsListGroupServicesV2 = ({
  handleEdit,
  handleOnTop,
  handleUpdateStatus,
}) => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'Tiêu đề nhóm',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Content',
      key: 'content',
      render: (_, record) => (
        <>
          <span className="text-[#274239] font-bricolage font-[400]">
            {record?.content?.text}
          </span>
          <span className="ml-2 text-[#769A6E] font-bricolage font-[400]">
            {record?.content?.hightlight}
          </span>
        </>
      ),
    },
    {
      title: 'Hiện trang chủ',
      key: 'show_home',
      render: (_, record) => (
        <>
          {record?.show_home ? (
            <span className="text-green-600">Hiển thị</span>
          ) : (
            <span className="text-red-600">Tạm ẩn</span>
          )}
        </>
      ),
    },
    {
      title: 'Kiểu hiển thị',
      key: 'minium',
      render: (_, record) => (
        <>
          {record?.minium ? (
            <span className="text-[#1677FF]">Thu gọn</span>
          ) : (
            <span className="text-green-600">Đầy đủ</span>
          )}
        </>
      ),
    },
    {
      title: 'Trạng thái',
      key: 'active',
      render: (_, record) => (
        <>
          {record?.active ? (
            <span className="text-green-600">Hiển thị</span>
          ) : (
            <span className="text-red-600">Tạm ẩn</span>
          )}
        </>
      ),
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record: DataType, i) => (
        <div className="flex">
          <Button
            type="primary"
            style={{ marginRight: 8 }}
            onClick={() => handleEdit(record)}
          >
            Sửa
          </Button>
          <Button
            type="default"
            style={{ marginRight: 8 }}
            onClick={() => handleUpdateStatus(record)}
          >
            Xoá
          </Button>
          {(PageIndex
            ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
            : i + 1) !== 1 && (
            <Button type="default" onClick={() => handleOnTop(record)}>
              Lên đầu
            </Button>
          )}
        </div>
      ),
    },
  ];

  return columns;
};

export const ColumnsListServicesV2 = ({
  handleEdit,
  handleOnTop,
  handleDelete,
}) => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'Hình ảnh',
      key: 'image',
      render: (_, record) => (
        <>
          <img alt="Facialbar" width="70px" src={record?.image} />
        </>
      ),
    },
    {
      title: 'Mã DV',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Tên dịch vụ',
      key: 'name',
      render: (_, record) => (
        <>
          <div>{record.name}</div>{' '}
          {!record.slug && (
            <div className="text-white bg-red-500 text-[10px] w-[100px] text-center rounded-[5px]">
              Chưa có bài viết
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Giá',
      key: 'price',
      render: (_, record) => (
        <>
          <span>
            {record.price ? record.price?.toLocaleString('de-DE') + ' đ' : ''}
          </span>
        </>
      ),
    },
    {
      title: 'Thời gian',
      key: 'time',
      render: (_, record) => (
        <>
          <span>{record.time} phút</span>
        </>
      ),
    },
    {
      title: 'Danh mục',
      key: 'group',
      render: (_, record) => (
        <>{record?.group && <span>{record?.group[0]?.name}</span>}</>
      ),
    },
    {
      title: 'Trạng thái',
      key: 'active',
      render: (_, record) => (
        <>
          {record?.active ? (
            <div className="text-green-600">Hiển thị</div>
          ) : (
            <div className="text-red-600">Không hiển thị</div>
          )}
        </>
      ),
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record: DataType, i) => (
        <div className="flex">
          <Button
            type="primary"
            style={{ marginRight: 8 }}
            onClick={() => handleEdit(record)}
          >
            Sửa
          </Button>
          <Button
            type="default"
            style={{ marginRight: 8 }}
            onClick={() => handleDelete(record)}
          >
            Xoá
          </Button>
          {(PageIndex
            ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
            : i + 1) !== 1 && (
            <Button type="default" onClick={() => handleOnTop(record)}>
              Lên đầu
            </Button>
          )}
        </div>
      ),
    },
  ];

  return columns;
};
